import { createSlice } from "@reduxjs/toolkit";
import {
  LoadInitialMsg,
  SignUpMetaMask,
  cashOutBeta,
  changeDefaultCurrency,
  changeName,
  getUserBetHistory,
  logInMetaMask,
  nAuthSigup,
  nauthLogin,
  placeBlueBetApi,
  placeGreenBetApi,
  placeNormalBet,
  placeRedBetApi,
  uploadProfileImage,
} from "./UserAction";

const initialState = {
  userData: null,
  loggedIn: false,
  defaultCurrency: 0,
  logInModal: false,
  signUpModal: false,
  profileModal: false,
  walletModal: false,
  betPlaced: false,
  betAmount: 0,
  nBetData: null,
  nbetstate: false,
  redBetData: null,
  greenBetData: null,
  blueBetData: null,
  nbetLeaderBoradAmount: 0,
  rleaderBoard: 0,
  gleaderBoard: 0,
  tempBet: null,
  chats: [],
  colourMode: null,
  redBetLoose: false,
  greenBetLoose: false,
  blueBetLoose: false,
  redBetActive: false,
  greenBetActive: false,
  blueBetActive: false,
  nbetLeaderBorad: [],
  rBetLeaderBoard: [],
  gBetLeaderBoard: [],
  userBetHistory: [],
};

const userController = createSlice({
  name: "userController",
  initialState,
  reducers: {
    logIn: (state) => {
      state.loggedIn = !state.loggedIn;
    },
    logOut: (state) => {
      state.loggedIn = false;
      state.userData = null;
      localStorage.clear();
    },
    closeSignUpModal: (state) => {
      state.signUpModal = false;
    },
    openSignUpModal: (state) => {
      state.signUpModal = true;
    },
    LogInModal: (state, action) => {
      state.logInModal = action.payload;
    },
    openProfileModal: (state) => {
      state.profileModal = true;
    },
    closeProfileModal: (state) => {
      state.profileModal = false;
    },
    openWalletModal: (state) => {
      state.walletModal = true;
    },
    closeWalletModal: (state) => {
      state.walletModal = false;
    },
    addChat: (state, action) => {
      state.chats = [...state.chats, action.payload];
    },
    addGbetLeaderBoardData: (state, action) => {
      state.gBetLeaderBoard = [...state.gBetLeaderBoard, action.payload];
    },

    addRbetLeaderBoardData: (state, action) => {
      state.rBetLeaderBoard = [...state.rBetLeaderBoard, action.payload];
    },

    addNBetLeaderBoard: (state, action) => {
      const updatedBetId = action.payload.betId;
      const existingBetIndex = state.nbetLeaderBorad.findIndex(
        (item) => item.betId === updatedBetId
      );

      if (existingBetIndex !== -1) {
        state.nbetLeaderBorad[existingBetIndex] = {
          ...state.nbetLeaderBorad[existingBetIndex],
          ...action.payload,
        };
      } else {
        state.nbetLeaderBorad = [...state.nbetLeaderBorad, action.payload];
      }
    },

    placeNBet: (state, action) => {
      state.nBetData = action.payload;
      state.tempBet = action.payload;
      state.betAmount = state.betAmount + action.payload?.amount;
      state.betPlaced = true;
    },

    cancelNBet: (state) => {
      state.betAmount = state.betAmount - state.betAmount?.amount;
      state.nBetData = null;
      if (!state.redBetData || !state.greenBetData || !state.blueBetData) {
        state.betPlaced = false;
      }
    },

    incermentBalance: (state, action) => {
      const { currencyIndex, amount } = action.payload;

      const updatedBalance = state.userData.userDetails.balance.map(
        (currency, i) => {
          if (i === currencyIndex) {
            const currentBalance = parseFloat(currency.balance.$numberDecimal);
            const newBalance = currentBalance + amount;
            return {
              ...currency,
              balance: { $numberDecimal: newBalance.toString() },
            };
          }
          return currency;
        }
      );

      state.userData.userDetails.balance = updatedBalance;
    },

    decermentBalance: (state, action) => {
      const { currencyIndex, amount } = action.payload;
      const updatedBalance = state.userData.userDetails.balance.map(
        (currency, i) => {
          if (i === currencyIndex) {
            const currentBalance = parseFloat(currency.balance.$numberDecimal);
            const newBalance = currentBalance - amount;
            return {
              ...currency,
              balance: { $numberDecimal: newBalance.toString() },
            };
          }
          return currency;
        }
      );

      state.userData.userDetails.balance = updatedBalance;
    },
    placeRedBet: (state, action) => {
      state.redBetData = action.payload;
      state.betPlaced = true;
    },
    placeBlueBet: (state, action) => {
      state.blueBetData = action.payload;
      state.betPlaced = true;
    },
    placeGreenBet: (state, action) => {
      state.greenBetData = action.payload;
      state.betPlaced = true;
    },
    cancelRedBet: (state) => {
      state.redBetData = null;
    },
    cancelGreenBet: (state) => {
      state.greenBetData = null;
    },
    cancelBlueBet: (state) => {
      state.blueBetData = null;
    },
    setDefaultCurrency: (state, action) => {
      state.defaultCurrency = action.payload;
    },
    resetBetState: (state, action) => {
      state.nbetstate = false;
    },
    updateNbetAmount: (state, action) => {
      state.nbetLeaderBoradAmount = action.payload;
    },
    resetColorLeaderBorad: (state, action) => {
      // state.gBetLeaderBoard = [];
      // state.rBetLeaderBoard = [];
      state.redBetLoose = false;
      state.greenBetLoose = false;
      state.redBetActive = false;
      state.greenBetActive = false;
      state.blueBetActive = false;
    },
    setLooseRedColour: (state, action) => {
      state.redBetLoose = true;
      state.redBetActive = false;
      state.greenBetActive = false;
    },
    setLooseGreenColour: (state, action) => {
      state.greenBetLoose = true;
      state.greenBetActive = false;
      state.redBetActive = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SignUpMetaMask.pending, (state) => {})
      .addCase(SignUpMetaMask.rejected, (state, action) => {})
      .addCase(SignUpMetaMask.fulfilled, (state, action) => {
        state.userData = action.payload?.data;
        state.loggedIn = true;
        state.signUpModal = false;
      });
    builder
      .addCase(cashOutBeta.pending, (state) => {})
      .addCase(cashOutBeta.rejected, (state, action) => {})
      .addCase(cashOutBeta.fulfilled, (state, action) => {
        state.nbetstate = false;
        const { coinId, amount } = action.payload;
        const updatedBalance = state.userData.userDetails.balance.map(
          (currency, i) => {
            if (i === coinId) {
              const currentBalance = parseFloat(
                currency.balance.$numberDecimal
              );
              const newBalance = currentBalance + amount;
              return {
                ...currency,
                balance: { $numberDecimal: newBalance.toString() },
              };
            }
            return currency;
          }
        );

        state.userData.userDetails.balance = updatedBalance;
      });
    builder
      .addCase(placeNormalBet.pending, (state) => {})
      .addCase(placeNormalBet.rejected, (state, action) => {})
      .addCase(placeNormalBet.fulfilled, (state, action) => {
        const { currencyIndex, amount } = action.payload;
        const updatedBalance = state.userData.userDetails.balance.map(
          (currency, i) => {
            if (i === currencyIndex) {
              const currentBalance = parseFloat(
                currency.balance.$numberDecimal
              );
              const newBalance = currentBalance - amount;
              return {
                ...currency,
                balance: { $numberDecimal: newBalance.toString() },
              };
            }
            return currency;
          }
        );

        state.userData.userDetails.balance = updatedBalance;
        state.nbetstate = true;
      });
    builder
      .addCase(placeRedBetApi.pending, (state) => {})
      .addCase(placeRedBetApi.rejected, (state, action) => {})
      .addCase(placeRedBetApi.fulfilled, (state, action) => {
        state.redBetData = null;
        state.redBetActive = true;
        const { currencyIndex, amount } = action.payload;

        const updatedBalance = state.userData.userDetails.balance.map(
          (currency, i) => {
            if (i === currencyIndex) {
              const currentBalance = parseFloat(
                currency.balance.$numberDecimal
              );
              const newBalance = currentBalance - amount;
              return {
                ...currency,
                balance: { $numberDecimal: newBalance.toString() },
              };
            }
            return currency;
          }
        );

        state.userData.userDetails.balance = updatedBalance;
        state.nbetstate = true;
      });
    builder
      .addCase(placeGreenBetApi.pending, (state) => {})
      .addCase(placeGreenBetApi.rejected, (state, action) => {})
      .addCase(placeGreenBetApi.fulfilled, (state, action) => {
        state.greenBetData = null;
        state.greenBetActive = true;
        const { currencyIndex, amount } = action.payload;
        console.log("greenBet api data: ", action.payload);
        const updatedBalance = state.userData.userDetails.balance.map(
          (currency, i) => {
            if (i === currencyIndex) {
              const currentBalance = parseFloat(
                currency.balance.$numberDecimal
              );
              const newBalance = currentBalance - amount;
              return {
                ...currency,
                balance: { $numberDecimal: newBalance.toString() },
              };
            }
            return currency;
          }
        );

        state.userData.userDetails.balance = updatedBalance;
        state.nbetstate = true;
      });
    builder
      .addCase(placeBlueBetApi.pending, (state) => {})
      .addCase(placeBlueBetApi.rejected, (state, action) => {})
      .addCase(placeBlueBetApi.fulfilled, (state, action) => {
        state.blueBetData = null;
        state.blueBetActive = true;
        const { currencyIndex, amount } = action.payload;
        const updatedBalance = state.userData.userDetails.balance.map(
          (currency, i) => {
            if (i === currencyIndex) {
              const currentBalance = parseFloat(
                currency.balance.$numberDecimal
              );
              const newBalance = currentBalance - amount;
              return {
                ...currency,
                balance: { $numberDecimal: newBalance.toString() },
              };
            }
            return currency;
          }
        );

        state.userData.userDetails.balance = updatedBalance;
        state.nbetstate = true;
      });
    builder
      .addCase(nAuthSigup.pending, (state) => {})
      .addCase(nAuthSigup.rejected, (state, action) => {})
      .addCase(nAuthSigup.fulfilled, (state, action) => {
        state.userData = action.payload;
        state.loggedIn = true;
        state.signUpModal = false;
      });
    builder
      .addCase(logInMetaMask.pending, (state) => {})
      .addCase(logInMetaMask.rejected, (state, action) => {})
      .addCase(logInMetaMask.fulfilled, (state, action) => {
        state.userData = action.payload?.data;
        state.loggedIn = true;
        state.logInModal = false;
      });
    builder
      .addCase(nauthLogin.pending, (state) => {})
      .addCase(nauthLogin.rejected, (state, action) => {})
      .addCase(nauthLogin.fulfilled, (state, action) => {
        state.userData = action.payload;
        state.loggedIn = true;
        state.logInModal = false;
      });
    builder
      .addCase(uploadProfileImage.pending, (state) => {})
      .addCase(uploadProfileImage.rejected, (state, action) => {})
      .addCase(uploadProfileImage.fulfilled, (state, action) => {
        state.userData.userDetails.profilePic = action.payload;
      });
    builder
      .addCase(changeName.pending, (state) => {})
      .addCase(changeName.rejected, (state, action) => {})
      .addCase(changeName.fulfilled, (state, action) => {
        state.userData.userDetails.name = action.payload;
      });

    builder
      .addCase(LoadInitialMsg.pending, (state) => {})
      .addCase(LoadInitialMsg.rejected, (state, action) => {})
      .addCase(LoadInitialMsg.fulfilled, (state, action) => {
        const chatData = action.payload;
        state.chats = chatData.reverse();
      });
    builder
      .addCase(changeDefaultCurrency.pending, (state) => {})
      .addCase(changeDefaultCurrency.rejected, (state, action) => {})
      .addCase(changeDefaultCurrency.fulfilled, (state, action) => {
        state.defaultCurrency = action.payload;
      });
    builder
      .addCase(getUserBetHistory.pending, (state) => {})
      .addCase(getUserBetHistory.rejected, (state, action) => {})
      .addCase(getUserBetHistory.fulfilled, (state, action) => {
        state.userBetHistory = action.payload;
      });
  },
});

export const {
  logIn,
  logOut,
  addGbetLeaderBoardData,
  addRbetLeaderBoardData,
  resetBetState,
  closeSignUpModal,
  openSignUpModal,
  closeProfileModal,
  openProfileModal,
  openWalletModal,
  LogInModal,
  setDefaultCurrency,
  closeWalletModal,
  addChat,
  placeNBet,
  placeBlueBet,
  placeGreenBet,
  placeRedBet,
  cancelBlueBet,
  cancelGreenBet,
  cancelNBet,
  cancelRedBet,
  incermentBalance,
  decermentBalance,
  addNBetLeaderBoard,
  setLooseGreenColour,
  setLooseRedColour,
  resetColorLeaderBorad,
} = userController.actions;
export default userController.reducer;
