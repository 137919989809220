import React from "react";
import ClassicPredictionCard from "./classicPredictionCard";
import betData from "./dummyBet.json";

function ClassicPrediction() {
  return (
    <div className="flex justify-center items-center w-auto h-[70vh] text-white border-2 rounded-xl overflow-hidden border-blue-700">
      <div className="flex mx-2" style={{ overflowX: 'auto' }}>
        {betData.map((data, index) => (
          <div key={index} className="m-4 ">
            <ClassicPredictionCard data={data} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ClassicPrediction;
