import React, { useState, useEffect, useRef, useContext } from "react";

import { FiSend } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { LoadInitialMsg, sendMsg } from "../../App/features/UserAction";
import { userData, userProfile, userchats } from "../../selector/userSelector";
import { SocketContext } from "../../Context/SocketContext";
import { addChat } from "../../App/features/UserSlice";
import toast from "react-hot-toast";

const ChatComp = () => {
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);

  const messagesEndRef = useRef(null);
  const msgData = useSelector(userchats);
  const profile = useSelector(userProfile);
  const userDb = useSelector(userData);

  useEffect(() => {
    dispatch(LoadInitialMsg());
  }, []);

  useEffect(() => {
    const handleIncomingChats = ({ newMsgData }) => {
      dispatch(addChat(newMsgData));
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    socket.on("newMsg", handleIncomingChats);
    return () => {
      socket.off("newMsg", handleIncomingChats);
    };
  }, []);

  const formatTimestamp = (timestamp_ms) => {
    const currentDate = new Date(timestamp_ms);
    let hour = currentDate.getHours();
    const minute = String(currentDate.getMinutes()).padStart(2, "0");
    const ampm = hour >= 12 ? "PM" : "AM";

    hour = hour % 12;
    hour = hour === 0 ? 12 : hour;

    return `${hour}:${minute} ${ampm}`;
  };

  const [input, setInput] = useState("");

  const sendMessage = (e) => {
    e.preventDefault();
    if (!userDb?.loggedIn) {
      toast.error("login your account to use chat feature", {
        position: "top-right",
        className: "mt-10",
        duration: 2000,
        style: {
          borderRadius: "10px",
          background: "#fff",
          color: "#3bc117",
          fontWeight:800
        },
      });
      return;
    }

    if (input.trim() !== "") {
      const obj = {
        msg: input,
        userId: profile?.userDetails?._id,
      };

      console.log("send data", obj);
      dispatch(sendMsg(obj));
    }
    setInput("");
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <div
      className="flex flex-col  text-white custom-mobile-menu"
      style={{ height: "calc(100vh - 74px)" }}
    >
      <div className="overflow-auto overflow-x-hidden p-2">
        {msgData?.map((msg) => (
          <div
            key={msg.id}
            className="flex items-start m-2 p-2 rounded-lg bg-grey-800  shadow-lg"
          >
            <img
              src={msg?.userDetails?.profilePic}
              alt="Profile"
              className="w-8 h-8 rounded-full mr-2"
            />
            <div className="flex flex-col flex-grow">
              <div>
                <span className="text-sm font-medium mr-2">
                  {msg?.userDetails?.name}
                </span>
                <span className="text-xs text-gray-300">
                  {formatTimestamp(msg?.timeStamp)}
                </span>
              </div>
              <div className="mt-1 max-w-md overflow-hidden overflow-ellipsis whitespace-wrap break-words">
                {msg?.msg}
              </div>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef}></div>
      </div>
      <div className="mt-auto mb-2 mx-2">
        <form onSubmit={sendMessage} className="flex items-center">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type a message"
            className="flex-grow p-2 rounded-l-lg bg-gray-700 text-white focus:outline-none"
          />

          <button
            type="submit"
            className="flex items-center p-2 rounded-r-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors"
          >
            Send <FiSend className="ml-2 text-white" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatComp;
