import { createSelector } from "@reduxjs/toolkit";

const gameSelector = (state) => state.game;

export const gameModeSelector = createSelector(
  [gameSelector],
  (game) => game.colourMode
);

export const gameDataSelector = createSelector(
  [gameSelector],
  (game) => game.gameData
);

export const gameLiveUserSelector = createSelector(
  [gameSelector],
  (game) => game.liveUser
);

export const gameStateSelector = createSelector(
  [gameSelector],
  (game) => game.gameState
);
