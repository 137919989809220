import React, { useState } from "react";
import { FaGoogle, FaEthereum } from "react-icons/fa";
import { metaMaskAuthHandler } from "../../blockchain/auth";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import {
  SignUpMetaMask,
  googleAuth,
  nAuthSigup,
} from "../../App/features/UserAction";

function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [referal, setreferal] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const validateEmail = () => {
    if (!email) {
      setEmailError("Email is required");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  const validatePassword = () => {
    if (!password) {
      setPasswordError("Password is required");
    } else {
      setPasswordError("");
    }
  };

  const SignUpInWithMetamsk = async () => {
    const SigningData = await metaMaskAuthHandler("SignUp To Dexcrash");
    const obj = {
      metamaskAddress: SigningData.walletAddress,
      signature: SigningData.signatureHash,
    };
    console.log("metamask data", SigningData);
    dispatch(SignUpMetaMask(obj));
  };

  const SignUpGoogle = async () => {
    dispatch(googleAuth());
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const normalSignUp = async () => {
    validateEmail();
    validatePassword();

    if (!emailError && !passwordError) {
      const obj = {
        email: email,
        password: password,
        referalId: referal,
      };

      dispatch(nAuthSigup(obj));
    }
  };

  return (
    <div className="bg-[#2d3035] p-8 h-[620px]">
      <h2 className="text-2xl font-semibold text-white">Signup</h2>

      <div className="mb-4">
        <label htmlFor="email" className="block text-gray-300 font-medium mb-2">
          Email
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={validateEmail}
          className={`w-full p-2 border rounded-md ${
            emailError ? "border-red-500" : "border-gray-300"
          } focus:ring focus:ring-blue-300 focus:border-blue-300 dark:focus:ring-blue-600 dark:focus:border-blue-600`}
          placeholder="Enter your email"
        />
        {emailError && <p className="text-red-500">{emailError}</p>}
      </div>
      <div className="mb-4">
        <label htmlFor="password" className="block text-white font-medium mb-2">
          Password
        </label>
        <div className="relative">
          <input
            type={showPassword ? "text" : "password"}
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onBlur={validatePassword}
            className={`w-full p-2 border rounded-md ${
              passwordError ? "border-red-500" : "border-gray-300"
            } focus:ring focus:ring-blue-300 focus:border-blue-300 dark:focus:ring-blue-600 dark:focus:border-blue-600`}
            placeholder="Enter your password"
          />

          <span
            onClick={togglePasswordVisibility}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
          >
            {showPassword ? (
              <IoEyeOffOutline size={20} className="text-blue-600" />
            ) : (
              <IoEyeOutline size={20} className="text-blue-600" />
            )}
          </span>
        </div>
        {passwordError && <p className="text-red-500">{passwordError}</p>}
      </div>
      <div className="mb-4">
        <label htmlFor="password" className="block text-white font-medium mb-2">
          Reffral Code
        </label>
        <input
          type="password"
          id="password"
          name="password"
          className="w-full p-2 border rounded-md border-gray-300 dark:border-gray-700 focus:ring focus:ring-blue-300 focus:border-blue-300 dark:focus:ring-blue-600 dark:focus:border-blue-600"
          placeholder="Enter your reffral code (optional)"
          value={referal}
          onChange={(e) => {
            setreferal(e.target.value);
          }}
        />
      </div>

      <button
        className="w-full bg-blue-700 text-white font-semibold py-2 rounded-md hover:bg-blue-600"
        onClick={normalSignUp}
      >
        Sign Up
      </button>

      <div className="w-full flex justify-center mt-8 items-center">
        <div className="w-full h-1 bg-gray-400"></div>
        <div className="w-full flex justify-center items-center text-gray-400 text-lg font-bold">
          Or Continue With
        </div>
        <div className="w-full h-1 bg-gray-400"></div>
      </div>

      <div className="w-full flex flex-col mt-4 space-y-4">
        <button
          className="bg-red-500 text-white flex justify-center items-center font-semibold py-2 rounded-md hover:bg-red-600 space-x-8"
          onClick={SignUpGoogle}
        >
          <FaGoogle size={18} />
          <span className="pl-2">Signup with Google</span>
        </button>
        <button
          className="bg-indigo-600 text-white flex justify-center items-center font-semibold py-2 rounded-md hover:bg-indigo-700 space-x-8"
          onClick={SignUpInWithMetamsk}
        >
          <FaEthereum size={18} />
          <span className="pl-2">Signup up with Metamask</span>
        </button>
      </div>
    </div>
  );
}

export default SignUp;
