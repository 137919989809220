import { Dropdown, Menu } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultUserCurrency,
  userCoins,
  userData,
} from "../../selector/userSelector";
import { incermentBalance, openWalletModal } from "../../App/features/UserSlice";
import { FaChevronDown } from "react-icons/fa";
import { PiWalletDuotone } from "react-icons/pi";
import {
  changeDefaultCurrency, getUserBetHistory,
} from "../../App/features/UserAction";
import { socket } from "../../Context/SocketContext";
import icons from "../../dummydata/img.json";

function WalletBtn() {
  const balance = useSelector(userCoins);
  const userDb = useSelector(userData);
  const defaultCurrency = useSelector(defaultUserCurrency);
  const dispatch = useDispatch();

  const handleCurrencyClick = (e) => {
    console.log("Selected Currency:", e.key);
    if (e.key === "currency1") {
      const obj = {
        userId: userDb?.userData?.userDetails?._id,
        currencyId: 0,
      };
      dispatch(changeDefaultCurrency(obj));
      return;
    }
    if (e.key === "currency2") {
      const obj = {
        userId: userDb?.userData?.userDetails?._id,
        currencyId: 1,
      };
      dispatch(changeDefaultCurrency(obj));
      return;
    }
    if (e.key === "currency3") {
      const obj = {
        userId: userDb?.userData?.userDetails?._id,
        currencyId: 2,
      };
      dispatch(changeDefaultCurrency(obj));
      return;
    }
  };

  useEffect(() => {
    const obj = {
      userId: userDb?.userData?.userDetails?.userId,
      userCrId: userDb?.userData?.userDetails?._id,
    };
    const userObject = {
      userId: userDb?.userData?.userDetails?._id,
      page : 0
    }
    dispatch(getUserBetHistory(userObject))
    socket.emit("_online_user", obj);
  }, []);

  useEffect(() => {
    socket.on("_payment_recived", ({currencyIndex , value}) => {
      alert("payment recived")
      dispatch(incermentBalance({currencyIndex:currencyIndex, amount:value}))
      
    });

    return () => {
      socket.off("_payment_recived");
    };
  }, []);

  const currencyMenu = () => (
    <Menu onClick={handleCurrencyClick} style={{ backgroundColor: "#242629" }}>
      <Menu.Item key="currency1">
        <div className="  text-white font-extrabold text-[14px]  h-[44px] flex justify-center items-center ">
          <div className="flex justify-center items-center">
            <img
              className="w-[28px] mr-2"
              alt="coin"
              src={icons[0]?.icon}
            ></img>
            <span className=" mr-20">Dex Mainnet Token</span>
          </div>
          <div style={{ flex: 1 }}></div>
          {balance[0]?.balance.$numberDecimal}
        </div>
      </Menu.Item>
      <Menu.Item key="currency2">
        <div className="  text-white font-extrabold text-[14px]  h-[44px] flex justify-center items-center ">
          <div className="flex justify-center items-center">
            <img
              className="w-[28px] mr-2"
              alt="coin"
              src={icons[1]?.icon}
            ></img>
            <span className=" mr-20">Dex Testnet Token</span>
          </div>
          <div style={{ flex: 1 }}></div>
          {balance[1]?.balance.$numberDecimal}
        </div>
      </Menu.Item>
      <Menu.Item key="currency3">
        <div className="  text-white font-extrabold text-[14px]  h-[44px] flex justify-center items-center ">
          <div className="flex justify-center items-center">
            <img
              className="w-[28px] mr-2"
              alt="coin"
              src={icons[2]?.icon}
            ></img>
            <span className=" mr-20">Dex Beta Token</span>
          </div>
          <div style={{ flex: 1 }}></div>
          {balance[2]?.balance.$numberDecimal}
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="bg-[#242629]">
      <Dropdown overlay={currencyMenu} className="bg-black" trigger={["click"]}>
        <button className="p-2 bg-[#242629] text-white font-extrabold md:text-[14px] text-[10px] h-[32px]  md:h-[44px] flex justify-center items-center ">
          <img
            className="md:w-[28px] w-[20px] mr-2"
            alt="coin"
            src={icons[defaultCurrency]?.icon}
          ></img>
          {balance?.[defaultCurrency]?.balance?.$numberDecimal}{" "}
          <FaChevronDown />
        </button>
      </Dropdown>

      <button
        className=" bg-blue-700 text-white font-extrabold md:text-[14px] text-[10px] rounded-md md:w-[116px] w-[64px] h-[32px] md:h-[44px] flex justify-center items-center hover:bg-blue-900"
        onClick={() => {
          dispatch(openWalletModal());
        }}
      >
        <PiWalletDuotone
          size={"24px"}
          style={{ marginRight: "4px" }}
          className=" hidden md:block"
        />{" "}
        Deposite
      </button>
    </div>
  );
}

export default WalletBtn;
