import { useState } from "react";
import { Layout } from "antd";
import TopNav from "./TopNav";
import SideBar from "./SideBar";
import { Outlet } from "react-router-dom";
import ChatComp from "../chat/ChatComp";
import MobileBottomNavigation from "./MobileBottomNavigation";

const { Header, Sider, Content } = Layout;

const Navbar = () => {
  const [collapsed, setCollapsed] = useState(true); 
  const [chatComp, setChatComp] = useState(true); // Set chatComp to true for initial collapse

  const CollapsedClose = (collapsedValue) => {
    setCollapsed(collapsedValue);
  };

  const chatController = (chatCompValue) => {
    setChatComp(chatCompValue);
  };

  return (
    <Layout style={{ overflowY: "hidden" }}>
      <Header style={{ padding: 0, background: "#000000"}} className="md:h-[74px] h-[58px]">
        <TopNav CollapsedClose={CollapsedClose} chatController={chatController} />
      </Header>
      <Layout hasSider>
        <Sider trigger={null} collapsible collapsed={collapsed} width={"260px"} style={{ backgroundColor: "#1c1e22" }}>
          <SideBar collapsed={collapsed} />
        </Sider>
        <Content className="main_content">
          <Outlet />
        
        </Content>
       
        <Sider trigger={null} collapsible collapsed={chatComp} collapsedWidth={0} width={"360px"} style={{ backgroundColor: "#1c1e22" }}>
          <ChatComp collapsed={chatComp} />
        </Sider>
      </Layout>
        <MobileBottomNavigation/>
    </Layout>
  );
};

export default Navbar;
