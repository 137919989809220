import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios/axiosInstance";

export const getLatestData = createAsyncThunk("game/getcrashData", async () => {
  try {
    const resendOtpResponse = await axiosInstance.get(
      "/game/crash-latest-history"
    );
    console.log("api data", resendOtpResponse);
    return resendOtpResponse;
  } catch (err) {
    if (err) {
      throw err;
    }
  }
});

export const btcChartDataApi = createAsyncThunk("game/BtcChartdata", async () => {
  try {
    const Response = await axiosInstance.get(
      "/game/btc-chart-data"
    );
    return Response;
  } catch (err) {
    if (err) {
      throw err;
    }
  }
});
