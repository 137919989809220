import React, { useState } from "react";
import { Switch } from "antd";
import { Space, Table, Tag } from "antd";
import "./prediction.css";
import AdvancedPrediction from "./advancedPrediction";
import ClassicPrediction from "./classicPrediction";

const columns = [
  {
    title: "Bet",
    dataIndex: "name",
    key: "name",
    render: (text) => <a href="/">{text}</a>,
  },
  {
    title: "Entry Price",
    dataIndex: "age",
    key: "age",
  },
  {
    title: "Amount",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Time",
    key: "tags",
    dataIndex: "tags",
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          let color = tag.length > 5 ? "geekblue" : "green";
          if (tag === "loser") {
            color = "volcano";
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </>
    ),
  },
  {
    title: "Profit Amount",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <a href="/">Invite {record.name}</a>
        <a href="/">Delete</a>
      </Space>
    ),
  },
];
const data = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
    tags: ["nice", "developer"],
  },
  {
    key: "2",
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
    tags: ["loser"],
  },
  {
    key: "3",
    name: "Joe Black",
    age: 32,
    address: "Sydney No. 1 Lake Park",
    tags: ["cool", "teacher"],
  },
];

const BetaPrediction = () => {
  const [classicMode, setClassicMode] = useState(true);
  const onChange = (checked) => {
    if (checked) {
      setClassicMode(checked);
    } else {
      setClassicMode(false);
    }
  };
  return (
    <>
      <div className="  p-2 ">
        <div className="h-full ">
          <div className="flex justify-center items-center w-full">
            <div className="text-white mb-4 w-[100%]">
              <span className=" text-white text-[18px] font-extrabold">
                Btc Price Prediction{" "}
              </span>
            </div>
            <div className="mb-4 flex w-[40%] mr-4">
              <div style={{ flex: 1 }}></div>
              <span className="mr-1 bg-gradient-to-r from-white to-blue-500 text-transparent bg-clip-text">
                Classic Mode
              </span>
              <Switch
                checked={classicMode}
                onChange={onChange}
                className=" bg-[#98a7b5] text-[14px]"
              />
            </div>
          </div>
          {classicMode ? <ClassicPrediction /> :  <AdvancedPrediction /> }
        </div>
        <div className="w-full h-[70vh]  mt-2 pr-4 pt-4  ">
          <div className="h-12  rounded-md mb-2 text-white flex justify-center items-center">
            <button className="rounded-md  border-blue-700 border-2 py-2 px-4 mr-4 bg-[#343435]">
              Active Bets
            </button>
            <button className="rounded-md  border-blue-700  py-2 px-4 bg-[#343435]">
              History
            </button>
            <div style={{ flex: 1 }}></div>
            <button className="rounded-md  border-blue-700  py-2 px-4 mr-4 bg-[#343435]">
              Recent
            </button>
            <button className="rounded-md  border-blue-700  py-2 px-4 bg-[#343435]">
              Lederboard
            </button>
          </div>
          <Table columns={columns} dataSource={data} className="" />
        </div>
      </div>
    </>
  );
};

export default BetaPrediction;
