import { Switch } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { FaCircle, FaUserAlt } from "react-icons/fa";
import { PiDotsNineFill } from "react-icons/pi";
import useCrashComp from "../crash/CrashComp";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  gameDataSelector,
  gameLiveUserSelector,
  gameModeSelector,
  gameStateSelector,
} from "../../selector/gameSelector";
import {
  addGameData,
  colorMode,
  normalMode,
  updateLiveUser,
} from "../../App/features/GameSlice";
import bull from "../../media/bull.png";
import bear from "../../media/bear.png";
import winImg from "../../media/dexcrash_win.png";
import { getLatestData } from "../../App/features/GameAction";
import { SocketContext } from "../../Context/SocketContext";
import DraggablePopup from "../crash/DragablePopup";

import {
  bbetPlaced,
  blueBetActiveStatus,
  defaultUserCurrency,
  gbetPlaced,
  greenBetActiveStatus,
  greenBetLederBoard,
  greenBetLoose,
  nbetPlaced,
  nbetstatus,
  normalBetLederBoard,
  rbetPlaced,
  redBetActiveStatus,
  redBetLederBoard,
  redBetLoose,
  userBetHistory,
  userCoins,
  userData,
} from "../../selector/userSelector";
import {
  addGbetLeaderBoardData,
  addNBetLeaderBoard,
  addRbetLeaderBoardData,
  cancelBlueBet,
  cancelGreenBet,
  cancelNBet,
  cancelRedBet,
  placeBlueBet,
  placeGreenBet,
  placeNBet,
  placeRedBet,
  setLooseGreenColour,
  setLooseRedColour,
} from "../../App/features/UserSlice";
import icons from "../../dummydata/img.json";
import {
  cashOutBeta,
  placeBlueBetApi,
  placeGreenBetApi,
  placeNormalBet,
  placeRedBetApi,
} from "../../App/features/UserAction";
function CrashMain() {
  const [history, sethistory] = useState(true);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [normalBetValue, setnormalBetValue] = useState(1);
  const [autoCashOut, setautoCashOut] = useState(100);
  const [colorBetValue, setcolorBetValue] = useState(1);
  const [timeLeft, setTimeLeft] = useState(10);

  const userDb = useSelector(userData);
  const { appRef, gameValue } = useCrashComp(userDb);
  const gameMode = useSelector(gameModeSelector);
  const gameData = useSelector(gameDataSelector);
  const liveUser = useSelector(gameLiveUserSelector);
  const gameState = useSelector(gameStateSelector);
  const nLeaderboard = useSelector(normalBetLederBoard);
  const defaultCoin = useSelector(defaultUserCurrency);
  const nbetState = useSelector(nbetstatus);
  const userBlance = useSelector(userCoins);
  const nbetData = useSelector(nbetPlaced);
  const rbetData = useSelector(rbetPlaced);
  const gbetData = useSelector(gbetPlaced);
  const bbetData = useSelector(bbetPlaced);
  const redLeaderboardData = useSelector(redBetLederBoard);
  const greenLeaderboardData = useSelector(greenBetLederBoard);
  const redBetActive = useSelector(redBetActiveStatus);
  const greenBetActive = useSelector(greenBetActiveStatus);
  const blueBetActive = useSelector(blueBetActiveStatus);
  const greenBoardLoose = useSelector(greenBetLoose);
  const redBoardLoose = useSelector(redBetLoose);
  const socket = useContext(SocketContext);
  const BetHistory = useSelector(userBetHistory);

  const dataRef = useRef(null);
  const dispatch = useDispatch();

  const enableHistory = () => {
    sethistory(!history);
  };

  const onChange = (checked) => {
    if (checked) {
      dispatch(colorMode());
    } else {
      dispatch(normalMode());
    }
  };

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 / 30 : prevTime));
    }, 1000 / 30);

    return () => clearInterval(countdown);
  }, []);

  const modeHandler = () => {
    if (!gameMode) {
      dispatch(colorMode());
    } else {
      dispatch(normalMode());
    }
  };

  useEffect(() => {
    dataRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  }, []);

  const handleNomalBetData = async ({ betData }) => {
    dispatch(addNBetLeaderBoard(betData));
  };

  const handleColourData = async ({ winner }) => {
    console.log("winnerdata is " + winner);
    if (winner === "red") {
      console.log("red is winner");
      dispatch(setLooseGreenColour());
    }
    if (winner === "green") {
      console.log("grren is winner");
      dispatch(setLooseRedColour());
    }
  };

  const handleGreenBetData = async ({ betData }) => {
    console.log("green bet event data", betData);
    dispatch(addGbetLeaderBoardData(betData));
  };

  const handleRedBetData = async ({ betData }) => {
    dispatch(addRbetLeaderBoardData(betData));
  };

  useEffect(() => {
    socket.on("normalBetEvent", handleNomalBetData);
    socket.on("colourBet", handleColourData);
    socket.on("greenBetEvent", handleGreenBetData);
    socket.on("RedBetEvent", handleRedBetData);

    return () => {
      socket.off("normalBetEvent", handelNormalBet);
      socket.off("colourBet", handleColourData);
      socket.off("greenBetEvent", handleGreenBetData);
      socket.off("RedBetEvent", handleRedBetData);
    };
  }, []);

  useEffect(() => {
    dispatch(getLatestData());
    const latestDataHandlder = ({ obj }) => {
      dispatch(addGameData(obj));
      console.log("data", obj);

      setTimeout(() => {
        dataRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }, 1000);
    };

    const handleLiveUser = ({ userCount }) => {
      dispatch(updateLiveUser(userCount));
    };
    socket.on("user count", handleLiveUser);
    socket.on("latestData", latestDataHandlder);

    return () => {
      socket.off("user count", handleLiveUser);
      socket.off("latestData", latestDataHandlder);
    };
  }, []);

  const userBetHandler = () => {
    if (!userDb?.loggedIn) {
      toast.error("login your account to place bet", {
        position: "top-right",
        className: "mt-10",
        duration: 2000,
        style: {
          borderRadius: "10px",
          background: "#1d4ed8",
          color: "#fff",
        },
      });
      return false;
    }
    if (
      userBlance[defaultCoin]?.balance.$numberDecimal < normalBetValue &&
      !nbetState
    ) {
      toast.error("insufficient fund", {
        position: "top-right",
        className: "mt-10",
        duration: 2000,
        style: {
          borderRadius: "10px",
          background: "#1d4ed8",
          color: "#fff",
        },
      });
      return false;
    }
    return true;
  };

  const handelNormalBet = () => {
    if (!userBetHandler()) {
      return;
    }

    if (gameState === 2 && !nbetData && !nbetState) {
      const obj = {
        currencyId: defaultCoin,
        userId: userDb?.userData?.userDetails?._id,
        amount: normalBetValue,
        coinId: defaultCoin,
        autoCashOut: autoCashOut,
        profilePic: userDb?.userData?.userDetails?.profilePic,
        name: userDb?.userData?.userDetails?.name,
      };
      dispatch(placeNBet(obj));
      return;
    }

    if (gameState === 2 && nbetState) {
      const obj = {
        userId: userDb?.userData?.userDetails?._id,
      };
      dispatch(cashOutBeta(obj));
    }

    if (gameState === 0 && !nbetData && !nbetState) {
      const obj = {
        currencyId: defaultCoin,
        userId: userDb?.userData?.userDetails?._id,
        amount: normalBetValue,
        coinId: defaultCoin,
        autoCashOut: autoCashOut,
        profilePic: userDb?.userData?.userDetails?.profilePic,
        name: userDb?.userData?.userDetails?.name,
      };
      dispatch(placeNormalBet(obj));
    }

    if (gameState === 1 && !nbetData && !nbetState) {
      const obj = {
        currencyId: defaultCoin,
        userId: userDb?.userData?.userDetails?._id,
        amount: normalBetValue,
        coinId: defaultCoin,
        autoCashOut: autoCashOut,
        profilePic: userDb?.userData?.userDetails?.profilePic,
        name: userDb?.userData?.userDetails?.name,
      };
      dispatch(placeNormalBet(obj));
    }

    if (gameState === 2 && nbetData) {
      dispatch(cancelNBet());
      return;
    }
    console.log("daddaar", gameState === 2 && !nbetData, nbetData);
  };

  const redBetHandler = () => {
    if (!userBetHandler()) {
      return;
    }
    if (gameState === 2 && !rbetData) {
      const obj = {
        currencyId: defaultCoin,
        userId: userDb?.userData?.userDetails?._id,
        amount: colorBetValue,
        coinId: defaultCoin,
        autoCashOut: autoCashOut,
        profilePic: userDb?.userData?.userDetails?.profilePic,
        name: userDb?.userData?.userDetails?.name,
      };
      dispatch(placeRedBet(obj));
      return;
    }

    if (gameState === 2 && rbetData) {
      dispatch(cancelRedBet());
      return;
    }

    if (gameState === 1 || gameState === 0) {
      const obj = {
        currencyId: defaultCoin,
        userId: userDb?.userData?.userDetails?._id,
        amount: colorBetValue,
        coinId: defaultCoin,
        autoCashOut: autoCashOut,
        profilePic: userDb?.userData?.userDetails?.profilePic,
        name: userDb?.userData?.userDetails?.name,
      };
      dispatch(placeRedBetApi(obj));
    }
  };

  const blueBetHandler = () => {
    if (!userBetHandler()) {
      return;
    }
    if (gameState === 2 && !bbetData) {
      const obj = {
        currencyId: defaultCoin,
        userId: userDb?.userData?.userDetails?._id,
        amount: colorBetValue,
        coinId: defaultCoin,
        autoCashOut: autoCashOut,
        profilePic: userDb?.userData?.userDetails?.profilePic,
        name: userDb?.userData?.userDetails?.name,
      };
      dispatch(placeBlueBet(obj));
      return;
    }

    if (gameState === 2 && bbetData) {
      dispatch(cancelBlueBet());
      return;
    }

    if (gameState === 1 || gameState === 0) {
      const obj = {
        currencyId: defaultCoin,
        userId: userDb?.userData?.userDetails?._id,
        amount: colorBetValue,
        coinId: defaultCoin,
        autoCashOut: autoCashOut,
        profilePic: userDb?.userData?.userDetails?.profilePic,
        name: userDb?.userData?.userDetails?.name,
      };
      dispatch(placeBlueBetApi(obj));
    }
  };

  const backgroundStyle = {
    width: "250px",
    backgroundImage: `linear-gradient(to right, rgba(15, 245, 20, 0.8) ${
      timeLeft.toFixed(2) * 10
    }%, rgba(82, 79, 80, 0.8) ${timeLeft * 10}%)`,
    transition: "background-image 1s linear",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const greenBetHandler = () => {
    if (!userBetHandler()) {
      return;
    }
    if (gameState === 2 && !gbetData) {
      const obj = {
        currencyId: defaultCoin,
        userId: userDb?.userData?.userDetails?._id,
        amount: colorBetValue,
        coinId: defaultCoin,
        autoCashOut: autoCashOut,
        profilePic: userDb?.userData?.userDetails?.profilePic,
        name: userDb?.userData?.userDetails?.name,
      };
      dispatch(placeGreenBet(obj));
      return;
    }

    if (gameState === 2 && gbetData) {
      dispatch(cancelGreenBet());
      return;
    }

    if (gameState === 1 || gameState === 0) {
      const obj = {
        currencyId: defaultCoin,
        userId: userDb?.userData?.userDetails?._id,
        amount: colorBetValue,
        coinId: defaultCoin,
        autoCashOut: autoCashOut,
        profilePic: userDb?.userData?.userDetails?.profilePic,
        name: userDb?.userData?.userDetails?.name,
      };
      dispatch(placeGreenBetApi(obj));
    }
  };

  return (
    <div className="md:p-8 w-full">
      <DraggablePopup
        isOpen={isPopupOpen}
        onClose={() => setPopupOpen(false)}
      ></DraggablePopup>

      <div className="w-full lg:h-[920px]  ">
        <div className="flex-col flex mt-2 lg:flex-row justify-center items-center">
          <div className="lg:w-[56%]  w-[100%] md:mr-4 ">
            <div className="mb-4 hidden md:block">
              <span className=" text-white text-[18px] font-extrabold">
                Dex Crash{" "}
              </span>
            </div>
            <div className="w-[100%] md:h-[820px] bg-[#17181b] pt-2 ">
              <div className="w-auto h-[32px] md:h-[48px] md:mx-2  flex justify-center items-center ">
                <div className="w-full h-full mx-2    overflow-hidden flex justify-center  items-center ">
                  <div className="flex justify-center items-center">
                    {gameData?.map((crash) => (
                      <div className="mx-1 flex justify-center items-center w-14 h-6   md:w-24 md:h-10">
                        <div className="md:mr-2">
                          {" "}
                          <FaCircle
                            style={{ color: crash?.color }}
                            className="md:text-[22px] text-[16px]"
                          />
                        </div>
                        <div className="flex flex-col">
                          <div className="text-white md:text-[16px] text-[10px] md:font-bold">
                            {crash?._id}
                          </div>
                          <div
                            style={{ color: crash?.color }}
                            className=" md:text-[16px] text-[10px] md:font-bold"
                          >
                            {crash?.crashedAt}X
                          </div>
                        </div>
                      </div>
                    ))}
                    <div ref={dataRef}></div>
                  </div>
                </div>
                <div
                  className="w-12 h-[40px] mx-2  flex justify-center items-center "
                  onClick={() => setPopupOpen(true)}
                >
                  <PiDotsNineFill
                    style={{ color: "white" }}
                    className="md:text-[40px] text-[32px]"
                  />
                </div>
              </div>
              <div className="w-full   h-auto md:px-8 md:py-4  bg-[#17181b]">
                <div
                  className="bg-gray-800"
                  style={{ width: "100%", height: "80%", overflow: "hidden" }}
                >
                  <div className="  game-container">
                    <div
                      className="w-full h-[220px] md:h-[280px] "
                      id="game-screen"
                      ref={appRef}
                    ></div>{" "}
                     <div className="centered-label">
                      <div className={"text-[22px] text-white font-[900]"}>
                        {gameValue}{" "}x
                      </div>
                    </div> 
                    {/* <div className="centered-label-6">
                      <div className="flex justify-center flex-col items-center">
                        <div
                          className={"text-[32px] text-[#fd7e2f] font-[900]"}
                        >
                          {2.8} x
                        </div>
                        <div
                          className={
                            "text-[16px] bg-gray-800 rounded-md px-10 text-[#fd7e2f] font-[900]"
                          }
                        >
                          crashed
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="centered-label">
                      <div style={backgroundStyle} className="py-1 rounded-md">
                        <div
                          style={{
                            color: "white",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          Starts In {timeLeft.toFixed(2)} {" "}s
                        </div>
                      </div>
                    </div>
                    <div className="centered-label-2 px-4 rounded-lg bg-gray-800">
                      <div className=" text-[18px] text-[#6aff49] font-[900] flex  justify-center items-center ">
                        My Win :{" "}
                        <img
                          src={icons[defaultCoin].icon}
                          alt="icon"
                          className="w-4 h-4 mx-2 "
                        ></img>{" "}
                        {gameValue} Dex
                      </div>
                    </div> */}
                    {/* <div className="centered-label">
                      <div className="w-full h-full flex justify-center items-center flex-col">
                      <h1 className=" text-[22px] bg-gradient-to-r  from-white to-blue-500 text-transparent bg-clip-text font-[900] ">you won 1 dex</h1>
                      <img src={winImg} className="h-[80%] w-[80%]" alt="win img"></img>
                      </div>
                    </div> */}
                  </div>
                </div>

                <div className="flex justify-center w-full mt-2 items-center h-[20%]">
                  <button
                    className={`w-[90px] h-[40px]${
                      gameMode
                        ? " bg-[#2d3035] text-[#f5f6f7]"
                        : "bg-[#24262b] text-[#98a7b5]"
                    } `}
                    onClick={() => {
                      modeHandler();
                    }}
                  >
                    Classic
                  </button>
                  <button
                    className={`w-[90px] h-[40px]${
                      !gameMode
                        ? " bg-[#2d3035] text-[#f5f6f7]"
                        : "bg-[#24262b] text-[#98a7b5]"
                    } `}
                    onClick={() => {
                      modeHandler();
                    }}
                  >
                    Colour
                  </button>
                </div>
              </div>
              <div className="w-full flex justify-center items-center md:h-[370px] bg-[#17181b]">
                {!gameMode ? (
                  <div className="w-[80%]  md:h-[244px] ">
                    <div className="h-[50%] flex justify-center items-start bg-[#17181b]">
                      <button
                        className={`md:w-[60%] w-[96%] mt-8 md:mt-0 md:h-16 h-12 flex flex-col justify-center rounded-md items-center md:text-[18px] text-[12px] font-bold text-white  py-2 ${
                          gameState === 2 && nbetState
                            ? "bg-yellow-600"
                            : "bg-blue-600"
                        } `}
                        onClick={() => {
                          handelNormalBet();
                        }}
                      >
                        <span>
                          {gameState === 2 && nbetState
                            ? "Cash Out"
                            : gameState === 2 && !nbetData && !nbetState
                            ? "Bet"
                            : gameState !== 2 && !nbetData && !nbetState
                            ? "BET"
                            : "Loading..."}
                        </span>
                        {gameState === 2 && !nbetState ? (
                          <span>
                            {gameState === 2 && !nbetState && !nbetData
                              ? "(Next Round)"
                              : "(Cancel)"}{" "}
                          </span>
                        ) : (
                          <></>
                        )}
                      </button>
                    </div>
                    <div className="h-[50%] flex flex-col md:flex-row  justify-center items-center ">
                      <div className="w-full mt-4 md:mt-0  bg-[#17181b] text-xs md:text-[18px]">
                        <div className="w-full mb-2 flex items-center h-[22px] text-white pl-4  ">
                          {" "}
                          <span className="mr-4">Amount</span>
                          <div className="flex justify-center items-center w-[16px] text-[14px] rounded-md h-[16px] border-blue-600 font-bold text-blue-600 border-[2px]">
                            !
                          </div>
                        </div>
                        <div className="w-full md:h-[44px] rounded-md h-10 flex justify-center items-center bg-[#31343c] opacity-4">
                          <div className="opacity-100">
                            <img
                              className="w-[28px]  h-[28px]"
                              alt="logo"
                              src={icons[defaultCoin].icon}
                            ></img>
                          </div>
                          <input
                            className="h-full md:w-[164px] ml-4 w-[34%] bg-transparent focus:border-none focus:outline-none p-2 text-white font-bold"
                            placeholder="1.00"
                            value={normalBetValue}
                            onChange={(e) => {
                              setnormalBetValue(e.target.value);
                            }}
                          ></input>
                          <div className="w-[136px]  flex justify-center items-center bg-[#31343c]  space-x-1 h-full py-1">
                            <button
                              className="w-[44px] cursor-pointer flex justify-center rounded  items-center h-[32px] md:h-[36px] bg-[#686b6e]  text-white"
                              onClick={() => {
                                setnormalBetValue(normalBetValue / 2);
                              }}
                            >
                              /2
                            </button>
                            <button
                              className="w-[44px] cursor-pointer flex justify-center rounded items-center h-[32px] md:h-[36px] bg-[#686b6e]  text-white"
                              onClick={() => {
                                setnormalBetValue(normalBetValue * 2);
                              }}
                            >
                              X2
                            </button>
                            <button
                              className="w-[44px] cursor-pointer flex justify-center rounded items-center md:h-[36px] h-[32px] bg-[#686b6e]  text-white"
                              onClick={() => {
                                setnormalBetValue(20000);
                              }}
                            >
                              Max
                            </button>
                          </div>
                        </div>
                        <div className="w-full hidden  h-[40px] md:flex justify-center items-center mt-2 space-x-2 ">
                          <button
                            className="w-[81px] cursor-pointer h-[40px] text-white flex justify-center items-center bg-[#222328] "
                            onClick={() => {
                              setnormalBetValue(normalBetValue + 10);
                            }}
                          >
                            10
                          </button>
                          <button
                            className="w-[81px] cursor-pointer h-[40px] text-white flex justify-center items-center bg-[#222328] "
                            onClick={() => {
                              setnormalBetValue(normalBetValue + 100);
                            }}
                          >
                            100
                          </button>
                          <button
                            className="w-[81px] cursor-pointer h-[40px] text-white flex justify-center items-center bg-[#222328] "
                            onClick={() => {
                              setnormalBetValue(normalBetValue + 1000);
                            }}
                          >
                            1000
                          </button>
                          <button
                            className="w-[81px] cursor-pointer h-[40px] text-white flex justify-center items-center bg-[#222328] "
                            onClick={() => {
                              setnormalBetValue(normalBetValue + 10000);
                            }}
                          >
                            10000
                          </button>
                        </div>
                      </div>
                      <div className="w-full mt-4 md:mt-0  bg-[#17181b] text-xs md:text-[18px]">
                        <div className="w-full mb-2 flex justify-center items-center h-[22px] text-white md:pl-4  ">
                          {" "}
                          <span className="mr-4">Auto Cash Out</span>
                          <div style={{ flex: 1 }}></div>
                          <span className="mr-2">Chance</span>
                          <span className=" text-blue-800 font-bold">
                            0.99%
                          </span>
                        </div>
                        <div className="w-full md:h-[44px] h-10 rounded-md flex justify-center items-center md:ml-4 bg-[#31343c] opacity-4">
                          <input
                            className="h-full md:w-[260px] w-full  bg-transparent focus:border-none focus:outline-none p-2 text-white font-bold"
                            placeholder="100.00"
                            value={autoCashOut}
                            onChange={(e) => {
                              setautoCashOut(e.target.value);
                            }}
                          ></input>
                          <IoCloseSharp
                            style={{ fontSize: "28px", color: "blue" }}
                          />
                        </div>
                        <div className="w-full h-[42px] flex justify-center items-center mt-2 space-x-2 "></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="w-[80%]  md:h-[244px] ">
                    <div className="h-[50%] flex flex-col justify-center mt-8 items-center ">
                      <div className="w-full  bg-[#17181b] text-[18px]">
                        <div className="w-full mb-2 flex items-center h-[22px] text-white pl-4  ">
                          {" "}
                          <span className="mr-4">Amount</span>
                          <div className="flex justify-center items-center w-[16px] text-[14px] rounded-md h-[16px] border-blue-600 font-bold text-blue-600 border-[2px]">
                            !
                          </div>
                        </div>
                        <div className="w-full h-[44px] flex justify-center items-center bg-[#31343c] opacity-4">
                          <div className="opacity-100">
                            <img
                              className="md:w-[28px] mx-4 md:h-[28px] h-[20px] w-[20px]"
                              alt="logo"
                              src={icons[defaultCoin].icon}
                            ></img>
                          </div>
                          <input
                            className="h-full md:w-full w-[40%] bg-transparent focus:border-none focus:outline-none p-2 text-white font-bold"
                            placeholder="1.00"
                            value={colorBetValue}
                            onChange={(e) => {
                              setcolorBetValue(e.target.value);
                            }}
                          ></input>
                          <div className="w-[136px] flex justify-center items-center mx-2 bg-[#31343c]  space-x-1 h-full py-1">
                            <button
                              className="w-[44px] cursor-pointer flex rounded justify-center  items-center h-[36px] bg-black  text-white"
                              onClick={() => {
                                setcolorBetValue(colorBetValue / 2);
                              }}
                            >
                              /2
                            </button>
                            <button
                              className="w-[44px] cursor-pointer rounded flex justify-center items-center h-[36px] bg-black  text-white"
                              onClick={() => {
                                setcolorBetValue(colorBetValue * 2);
                              }}
                            >
                              X2
                            </button>
                            <button
                              className="w-[44px] cursor-pointer rounded flex justify-center  items-center h-[36px] bg-black text-white"
                              onClick={() => {
                                setcolorBetValue(20000);
                              }}
                            >
                              Max
                            </button>
                          </div>
                        </div>
                        <div className="w-full hidden  h-[40px] md:flex justify-center items-center mt-2 space-x-2 ">
                          <button
                            className="w-full h-[40px] cursor-pointer text-white flex justify-center items-center bg-[#222328] "
                            onClick={() => {
                              setcolorBetValue(colorBetValue + 10);
                            }}
                          >
                            10
                          </button>
                          <button
                            className="w-full h-[40px] cursor-pointer text-white flex justify-center items-center bg-[#222328] "
                            onClick={() => {
                              setcolorBetValue(colorBetValue + 100);
                            }}
                          >
                            100
                          </button>
                          <button
                            className="w-full h-[40px] cursor-pointer text-white flex justify-center items-center bg-[#222328] "
                            onClick={() => {
                              setcolorBetValue(colorBetValue + 100);
                            }}
                          >
                            1000
                          </button>
                          <button
                            className="w-full h-[40px] cursor-pointer text-white flex justify-center items-center bg-[#222328] "
                            onClick={() => {
                              setcolorBetValue(colorBetValue + 10000);
                            }}
                          >
                            10000
                          </button>
                        </div>
                      </div>
                      <div className="w-full mt-8 bg-[#17181b] text-xs md:text-[18px]">
                        <div className="w-full flex-col md:flex-row  flex justify-center items-center mt-2 space-y-2 md:space-y-0 md:space-x-4 ">
                          <div className="w-full  text-white flex md:flex-col flex-row justify-center items-start">
                            <span className="text-base md:mb-1">
                              Bet Red Payout 1.96X
                            </span>
                            <button
                              className={`w-full cursor-pointer h-[48px] text-white flex justify-center font-bold rounded-md border border-white items-center  ${
                                !rbetData ? "bg-[#222328]" : "bg-[#ed6300]"
                              } `}
                              onClick={() => {
                                redBetHandler();
                              }}
                              disabled={redBetActive}
                            >
                              {!rbetData ? (
                                <FaCircle
                                  style={{
                                    color: "#ed6300",
                                    fontSize: "28px",
                                    marginRight: "18px",
                                  }}
                                />
                              ) : (
                                <></>
                              )}

                              <div className="flex-col flex justify-center items-center text-sm">
                                <span>
                                  {!redBetActive && !rbetData
                                    ? "Bet"
                                    : "Loading..."}
                                </span>
                                {gameState === 2 && !nbetState ? (
                                  <span>
                                    {gameState === 2 && !nbetState && !nbetData
                                      ? "(Next Round)"
                                      : "(Cancel)"}{" "}
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </button>
                          </div>
                          <div className="w-full  text-white flex md:flex-col flex-row justify-center items-start">
                            <span className=" text-base mb-1">
                              Bet Green Payout 2X
                            </span>
                            <button
                              className={`w-full cursor-pointer h-[48px] text-white flex justify-center font-bold rounded-md border border-white items-center  ${
                                !gbetData ? "bg-[#222328]" : "bg-[#3bc117]"
                              } `}
                              onClick={() => {
                                greenBetHandler();
                              }}
                              disabled={greenBetActive}
                            >
                              {!gbetData ? (
                                <FaCircle
                                  style={{
                                    color: "#3bc117",
                                    fontSize: "28px",
                                    marginRight: "18px",
                                  }}
                                />
                              ) : (
                                <></>
                              )}

                              <div className="flex-col flex justify-center items-center text-sm">
                                <span>
                                  {!greenBetActive && !gbetData
                                    ? "Bet"
                                    : "Loading..."}
                                </span>
                                {gameState === 2 && !nbetState ? (
                                  <span>
                                    {gameState === 2 && !nbetState && !nbetData
                                      ? "(Next Round)"
                                      : "(Cancel)"}{" "}
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </button>
                          </div>
                          <div className="w-full cursor-pointer  text-white flex md:flex-col flex-row justify-center items-start">
                            <span className=" text-base mb-1">
                              Bet Blue Payout 10X
                            </span>
                            <button
                              className={`w-full cursor-pointer h-[48px] text-white flex justify-center font-bold rounded-md border border-white items-center  ${
                                !bbetData ? "bg-[#222328]" : "bg-[#1d4ed8]"
                              } `}
                              onClick={() => {
                                blueBetHandler();
                              }}
                              disabled={blueBetActive}
                            >
                              {!bbetData ? (
                                <FaCircle
                                  style={{
                                    color: "#1d4ed8",
                                    fontSize: "28px",
                                    marginRight: "18px",
                                  }}
                                />
                              ) : (
                                <></>
                              )}

                              <div className="flex-col flex justify-center items-center text-sm">
                                <span>
                                  {!blueBetActive && !bbetData
                                    ? "Bet"
                                    : "Loading..."}
                                </span>
                                {gameState === 2 && !nbetState ? (
                                  <span>
                                    {gameState === 2 && !nbetState && !nbetData
                                      ? "(Next Round)"
                                      : "(Cancel)"}{" "}
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className="w-full h-[42px] flex justify-center items-center mt-2 space-x-2 "></div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="lg:w-[40%] w-full mr-4  ">
            <div className="lg:flex hidden  justify-center items-center text-white mb-2">
              <div className="text-[18px] font-extrabold">All Bet</div>
              <div style={{ flex: 1 }}></div>
              <div className="text-[18px] font-extrabold text-[#98a7b5] mr-4">
                <span className="mr-1 bg-gradient-to-r from-white to-blue-500 text-transparent bg-clip-text">
                  Colour
                </span>
                <Switch
                  checked={gameMode}
                  className=" bg-[#98a7b5] text-[14px]"
                  onChange={onChange}
                />
              </div>
            </div>

            {!gameMode ? (
              <div className="w-[100%] h-[820px] p-4 bg-[#1e2024]  ">
                <div className="w-[100%] h-[38px] p-4 bg-[#24262b] flex justify-center items-center ">
                  <div className="text-[18px] flex justify-center items-center  font-extrabold text-white">
                    <FaUserAlt
                      style={{
                        color: "3bc117",
                        fontSize: "18px",
                        marginRight: "8px",
                      }}
                    />
                    {liveUser}
                  </div>
                  <div style={{ flex: 1 }}></div>
                  <div className="text-[18px] font-extrabold text-white mr-4">
                    <span className="mr-1">₹7,44,031.07</span>{" "}
                  </div>
                </div>
                <div className="w-[100%] h-[48px] opacity-60 text-[#99a4b0] flex justify-center items-center ">
                  <div className="flex justify-center items-center text-[16px]  w-[100%] font-extrabold">
                    Player
                  </div>
                  <div className=" flex justify-center items-center text-[16px] p-6 w-[100%] font-extrabold ">
                    Cash Out
                  </div>
                  <div className=" flex justify-center items-center text-[16px] p-6 w-[100%] font-extrabold">
                    Amount
                  </div>
                  <div className=" flex  justify-end items-end text-[16px] p-6 w-[100%] font-extrabold">
                    Profit
                  </div>
                </div>
                <div className="overflow-auto overflow-x-hidden h-[80%] p-2">
                  {nLeaderboard?.map((msg) => (
                    <div className="w-[100%] h-[48px]   flex justify-center items-center ">
                      <div class="flex justify-center items-center text-[16px]  w-[100%] font-extrabold">
                        <img
                          src={msg?.profilePic}
                          alt="eth"
                          class="w-[22px] h-[22px] mr-2 rounded-[50%]"
                        ></img>
                        <span
                          className="text-white"
                          style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                        >
                          {msg.name}
                        </span>
                      </div>
                      <div className=" flex justify-center items-center text-[16px]  opacity-80 text-[#ffffff] p-6 w-[100%] font-extrabold ">
                        {msg.cashOut
                          ? `${msg.cashOutAt.toFixed(2)}X`
                          : "Betting"}
                      </div>
                      <div class="flex justify-center items-center text-[16px] p-6 w-[100%] font-extrabold">
                        <img
                          src={icons[msg.coinId].icon}
                          alt="eth"
                          class="w-[22px] h-[22px] mr-2"
                        ></img>
                        <span
                          className="text-white"
                          style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                        >
                          $ {msg.amount}
                        </span>
                      </div>

                      <div
                        className={`flex    ${
                          msg.cashOut
                            ? "text-[#3bc117]"
                            : "opacity-60 text-[#99a4b0]"
                        }  justify-end items-end text-[16px] p-6 w-[100%] font-extrabold`}
                      >
                        <img
                          src={icons[msg.coinId].icon}
                          alt="eth"
                          class="w-[22px] h-[22px] mr-2"
                        ></img>
                        <span>
                          {msg.cashOut
                            ? `$ ${msg.cashOutAmount.toFixed(2)}`
                            : "Betting"}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="w-[100%] h-[820px] p-4 bg-[#1e2024]  ">
                <div className="flex justify-center mt-2 items-center">
                  <div
                    className={`flex justify-center ${
                      redBoardLoose ? "grayscale" : ""
                    } flex-col w-full items-center`}
                  >
                    <img
                      className="w-[62px] rounded-[50%] ml-4"
                      alt="vs"
                      src={bear}
                    ></img>
                    <span className=" text-[#ed6300] ml-4 text-lg">
                      Red Colour
                    </span>
                  </div>
                  <div className="flex justify-center w-[30%] items-center">
                    <img
                      className="w-[52px]"
                      alt="vs"
                      src="https://bc.game/assets/knife.f801071a.png"
                    ></img>
                  </div>
                  <div
                    className={`flex justify-center ${
                      greenBoardLoose ? "grayscale" : ""
                    } flex-col w-full items-center`}
                  >
                    <img
                      className="w-[62px]  rounded-[50%] mr-4"
                      alt="vs"
                      src={bull}
                    ></img>
                    <span className=" text-[#3bc117] text-lg mr-4">
                      Green Colour
                    </span>
                  </div>
                </div>
                <div className="flex justify-center space-x-2 items-center">
                  <div
                    className={`w-full ${
                      redBoardLoose ? "grayscale" : ""
                    }  flex flex-col justify-start items-center`}
                  >
                    <div class="h-[60px] border-[#ed6300] border-t-[12px]  rounded-t-[12px] flex justify-center items-center bg-gradient-to-b from-blue-800  w-full">
                      <div className="ml-2">
                        <span className="text-white text-base font-bold">
                          Players
                        </span>
                        <span className="text-blue-600 text-base font-bold">
                          {" "}
                          131
                        </span>
                      </div>
                      <div style={{ flex: 1 }}></div>
                      <div className="mr-2">
                        <span className="text-[#ed6300] text-base font-bold">
                          ₹2,93,742.10
                        </span>
                      </div>
                    </div>
                    <div className="font-bold p-2 w-full flex justify-center items-center text-white">
                      <span>Player</span>
                      <div style={{ flex: 1 }}></div>
                      <span>Bet</span>
                    </div>
                  </div>
                  <div
                    className={`w-full ${
                      greenBoardLoose ? "grayscale" : ""
                    }  flex flex-col justify-start items-center`}
                  >
                    <div class="h-[60px] border-[#3bc117] border-t-[12px]  rounded-t-[12px] flex justify-center items-center bg-gradient-to-b from-blue-800  w-full">
                      <div className="ml-2">
                        <span className="text-white text-base font-bold">
                          Players
                        </span>
                        <span className="text-blue-600 text-base font-bold">
                          {" "}
                          131
                        </span>
                      </div>
                      <div style={{ flex: 1 }}></div>
                      <div className="mr-2">
                        <span className="text-[#3bc117] text-base font-bold">
                          ₹2,93,742.10
                        </span>
                      </div>
                    </div>
                    <div className="font-bold p-2 w-full flex justify-center items-center text-white">
                      <span>Player</span>
                      <div style={{ flex: 1 }}></div>
                      <span>Bet</span>
                    </div>
                  </div>
                </div>
                <div className="h-[1px] bg-gray-400 w-full"></div>
                <div className="flex justify-center  mt-2 h-full items-start">
                  <div
                    className={`w-full overflow-auto overflow-x-hidden ${
                      redBoardLoose ? "grayscale" : ""
                    }   p-2 h-[72%]`}
                  >
                    {redLeaderboardData?.map((msg) => (
                      <div className="w-[100%] h-[48px] rounded-md hover:bg-gray-700 px-1   flex justify-center items-center ">
                        <div class="flex justify-start items-center text-[16px]  w-full font-extrabold">
                          <img
                            src={msg?.profilePic}
                            alt="eth"
                            class="w-[22px] h-[22px] mr-2 rounded-[50%]"
                          ></img>
                          <span
                            className="text-white"
                            style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                          >
                            {msg.name}
                          </span>
                        </div>
                        <div style={{ flex: 1 }}></div>
                        <div class="flex justify-end items-center text-[16px]  w-full font-extrabold">
                          <span
                            className="text-white"
                            style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                          >
                            $ {msg?.amount}
                          </span>
                          <img
                            src={icons[msg.coinId].icon}
                            alt="coin"
                            class="w-[22px] h-[22px] mr-2 rounded-[50%]"
                          ></img>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div
                    className={`w-full overflow-auto overflow-x-hidden ${
                      greenBoardLoose ? "grayscale" : ""
                    }   p-2 h-[72%]`}
                  >
                    {greenLeaderboardData?.map((msg) => (
                      <div className="w-[100%] h-[48px] rounded-md hover:bg-gray-700 px-1   flex justify-center items-center ">
                        <div class="flex justify-start items-center text-[16px]  w-full font-extrabold">
                          <img
                            src={msg?.profilePic}
                            alt="eth"
                            class="w-[22px] h-[22px] mr-2 rounded-[50%]"
                          ></img>
                          <span
                            className="text-white"
                            style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                          >
                            {msg?.name}
                          </span>
                        </div>
                        <div style={{ flex: 1 }}></div>
                        <div class="flex justify-end items-center text-[16px]  w-full font-extrabold">
                          <span
                            className="text-white"
                            style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                          >
                            $ {msg?.amount}
                          </span>
                          <img
                            src={icons[msg.coinId].icon}
                            alt="coin"
                            class="w-[22px] h-[22px] mr-2 rounded-[50%]"
                          ></img>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* history section */}
      <div className="mt-8 text-white text-[26px] mb-4 font-bold">
        History And Statistics
      </div>
      <div className="w-full p-4 h-[920px] bg-[#1c1e22] ">
        <div className="w-[188px] h-[56px] flex justify-center items-center bg-[#24262b] hover:text-white text-[#98a7b5]">
          <div
            className={`w-full h-full ${
              history ? "text-white bg-[#2d3035]" : ""
            }  font-bold  flex justify-center items-center`}
            onClick={enableHistory}
          >
            History
          </div>
          <div
            className={`w-full h-full ${
              !history ? "text-white bg-[#2d3035]" : ""
            }  font-bold  flex justify-center items-center`}
            onClick={enableHistory}
          >
            My Bets
          </div>
        </div>
        {!history ? (
          <>
            {" "}
            <div className="bg-white mt-2 flex justify-center items-center w-full h-[50px]">
              <div className="w-full flex justify-center items-center text-[18px] font-bold h-[50px] text-[#98a7b5] bg-[#1c1e22]">
                Bet Id
              </div>
              <div className="w-full flex justify-center items-center text-[18px] font-bold h-[50px] text-[#98a7b5] bg-[#1c1e22]">
                Bet
              </div>
              <div className="w-[80%] flex justify-center items-center text-[18px] font-bold h-[50px] text-[#98a7b5] bg-[#1c1e22]">
                Time
              </div>
              <div className="w-[80%] flex justify-center items-center text-[18px] font-bold h-[50px] text-[#98a7b5] bg-[#1c1e22]">
                Payout
              </div>
              <div className="w-full flex justify-center items-center text-[18px] font-bold h-[50px] text-[#98a7b5] bg-[#1c1e22]">
                Profit
              </div>
            </div>
            <div className="overflow-auto overflow-x-hidden h-[80%] mt-2 p-2">
              {BetHistory?.map((data) => (
                <div className="bg-white mt-4 flex justify-center items-center w-full h-[50px]">
                  <div className="w-full flex justify-center items-center text-[18px] font-bold h-[50px] text-[#98a7b5] bg-[#1c1e22]">
                    {data?.betId}
                  </div>
                  <div className="w-full flex justify-center items-center text-[18px] font-bold h-[50px] text-[#98a7b5] bg-[#1c1e22]">
                    <img
                      src={icons[data?.currencyId].icon}
                      alt="eth"
                      class="w-[22px] h-[22px] mr-2"
                    ></img>
                    <span
                      className="text-white"
                      style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                    >
                      $ {data?.betAmount?.$numberDecimal.toString()}
                    </span>
                  </div>
                  <div className="w-[80%] flex justify-center items-center text-[18px] font-bold h-[50px] text-[#98a7b5] bg-[#1c1e22]">
                    20:36:07
                  </div>
                  <div className="w-[80%] flex justify-center items-center text-[18px] font-bold h-[50px] text-[#98a7b5] bg-[#1c1e22]">
                    1.96×
                  </div>
                  <div className="w-full flex justify-center items-center text-[18px] font-bold h-[50px] text-[#98a7b5] bg-[#1c1e22]">
                    <span
                      className={` ${
                        data.profit ? " text-[#3bc117]" : "text-[#ed6300]"
                      }`}
                      style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                    >
                      $ {Number(data?.profitAmount?.$numberDecimal).toFixed(2)}
                    </span>
                    <img
                      src={icons[data?.currencyId].icon}
                      alt="eth"
                      class="w-[22px] h-[22px] ml-2"
                    ></img>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="bg-white mt-2 flex justify-center items-center w-full h-[50px]">
              <div className="w-[30%] flex justify-start pl-4 items-center text-[18px] font-bold h-[50px] text-[#98a7b5] bg-[#1c1e22]">
                Game Id
              </div>
              <div className="w-[20%] flex justify-start pl-4 items-center text-[18px] font-bold h-[50px] text-[#98a7b5] bg-[#1c1e22]">
                Result
              </div>
              <div className="w-[80%] flex justify-end pr-4 items-center text-[18px] font-bold h-[50px] text-[#98a7b5] bg-[#1c1e22]">
                hash
              </div>
            </div>
            <div className="overflow-auto flex flex-col-reverse overflow-x-hidden h-[80%] mt-2 p-2">
              {gameData?.map((crash) => (
                <div className="bg-white mt-4 flex justify-center items-center w-full h-[50px]">
                  <div className="w-[30%] flex justify-start pl-4 items-center text-[18px] font-bold h-[50px] text-[#98a7b5] bg-[#1c1e22]">
                    {crash?._id}
                  </div>
                  <div className="w-[20%] flex justify-start pl-4 items-center text-[18px] font-bold h-[50px] text-[#98a7b5] bg-[#1c1e22]">
                    {crash?.crashedAt}x
                  </div>
                  <div className="w-[80%] flex justify-end items-center text-[18px] font-bold h-[50px] text-[#98a7b5] bg-[#1c1e22]">
                    <span className="mr-auto">{crash?.crashHash}</span>
                    <span>Verify</span>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default CrashMain;
