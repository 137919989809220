import React from 'react';
import Draggable from 'react-draggable';
import './DraggablePopup.css';
import { IoClose } from "react-icons/io5";
const DraggablePopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <Draggable handle=".handle" bounds="body">
      <div className="popup bg-gray-500">
        <div className='flex  bg-black text-white font-bold justify-center items-center'>
        <div className="handle w-full ml-4 cursor-grab">Trends !</div>

        <IoClose style={{fontSize:"20px", marginRight:"10%" }} onClick={onClose}/>
        </div>
        <div className='w-8 , h-8 bg-blue-400 p-1'>
            
        </div>

      <div className='w-full bg-gray-500 h-40'></div>
      </div>
    </Draggable>
  );
}

export default DraggablePopup;
