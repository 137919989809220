import { createBrowserRouter } from "react-router-dom";
import LayOut from "../pages/layout/Navbar";
import CrashMain from "../pages/crash/CrashMain";
import ChatComp from "../pages/crash/CrashComp";

import BetaPrediction from "../pages/livePrediction/betaPrediction";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <LayOut />,
    children: [
      {
        path: "",
        element: <CrashMain />,
      },
      {
        path: "chat",
        element: <ChatComp />,
      },
      {
        path: "betaprediction",
        element: <BetaPrediction />,
      },
      {
        path: "LeaderBoard",
        element: <CrashMain />,
      },
      {
        path: "Buy",
        element: <CrashMain />,
      },
      {
        path: "Swap",
        element: <CrashMain />,
      },
      {
        path: "DashBoard",
        element: <CrashMain />,
      },
      {
        path: "DashBoard",
        element: <CrashMain />,
      },
    ],
  },
]);
