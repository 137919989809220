import { createSlice } from "@reduxjs/toolkit";
import { btcChartDataApi, getLatestData } from "./GameAction";

const initialState = {
  colourMode: false,
  gameData: [],
  liveUser:12,
  normalMode:null,
  gameState:0,
  btcChartData:[]
};

const gameController = createSlice({
  name: "gameController",
  initialState,
  reducers: {
    colorMode: (state) => {
      state.colourMode = true;
    },
    normalMode: (state) => {
      state.colourMode = false;
    },
    addGameData:(state,action) => {
      const data = action.payload
      state.gameData = [...state.gameData, data];
    },
    updateLiveUser:(state,action) => {
      state.liveUser = action.payload
    },
    updateGameStae:(state, action) => {
      state.gameState = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLatestData.pending, (state) => {
        state.gameData = [];
      })
      .addCase(getLatestData.rejected, (state, action) => {
        state.gameData = [];
      })
      .addCase(getLatestData.fulfilled, (state, action) => {
        state.gameData = action.payload?.data.history;
      });
    builder
      .addCase(btcChartDataApi.pending, (state) => {
        state.btcChartData = [];
      })
      .addCase(btcChartDataApi.rejected, (state, action) => {
        state.btcChartData = [];
      })
      .addCase(btcChartDataApi.fulfilled, (state, action) => {
        state.btcChartData = action.payload.data;
      });
  },
});

export const { normalMode, colorMode , addGameData, updateLiveUser , updateGameStae } = gameController.actions;
export default gameController.reducer;
