import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { userProfile } from "../../selector/userSelector";
import icons from "../../dummydata/img.json";

function WalletModal() {
  const [selectedToken, setSelectedToken] = useState("DEX");
  const [balance, setBalance] = useState(); // Dummy balance for demonstration
  const user = useSelector(userProfile);
  const tokens = [
    {
      value: "DEX",
      label: "DEX",
      balance: Number(user?.userDetails?.balance[0]?.balance.$numberDecimal),
      logo: icons[0].icon,
    },
    {
      value: "DEXT",
      label: "DEXT",
      balance: Number(user?.userDetails?.balance[1]?.balance.$numberDecimal),
      logo: icons[1].icon,
    },
    {
      value: "BETA",
      label: "BETA",
      balance: Number(user?.userDetails?.balance[2]?.balance.$numberDecimal),
      logo: icons[2].icon,
    },
    // Add more tokens as needed
  ];

  const handleTokenChange = (value) => {
    const token = tokens.find((t) => t.value === value);
    setSelectedToken(token.value);
    setBalance(token.balance);
  };

  useEffect(() => {
    setBalance(user?.userDetails?.balance[0]?.balance.$numberDecimal);
  }, []);

  return (
    <div className="bg-gradient-to-b from-[#2d3035] to-[#1a1c1f] p-8  flex flex-col justify-between">
      <div className="mb-6">
        {tokens.map((token) => (
          <button
            key={token.value}
            onClick={() => handleTokenChange(token.value)}
            className={`m-2 p-2 rounded-lg ${
              selectedToken === token.value
                ? "bg-white text-[#2d3035]"
                : "bg-[#2d3035] text-white"
            } transition duration-300 ease-in-out hover:shadow-md`}
          >
            <img
              src={token.logo}
              alt={token.label}
              className="w-6 h-6 rounded-[50%] inline-block mr-2"
            />
            {token.label}
          </button>
        ))}
      </div>

      <div className="flex flex-col justify-center items-center text-center mb-6">
        <h2 className="text-4xl font-bold text-white mb-6">
          Deposit Address for {selectedToken}
        </h2>

        <img
          src={`https://apidexcrash.web5lab.net/user/get-qr?ad=${user?.userDetails?.DepositeAddress}`}
          alt="QR Code"
          className="w-[40%] mb-6 shadow-lg"
        />

        <div className="text-white font-bold text-lg  mt-2 mb-6">
          Balance: {balance} {selectedToken}
        </div>

        <div className=" w-full p-6 rounded-xl shadow-md mb-6">
          <div className="text-gray-400 text-sm mb-3">
            Your Unique Address for {selectedToken}
          </div>
          <div className="bg-[#2d3035] w-full p-4 rounded-md relative">
            <span className="text-white font-mono break-all">
              {user?.userDetails?.DepositeAddress}{" "}
              {/* This should ideally change based on selectedToken */}
            </span>
            <button className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-[#2d3035] text-white px-4 py-1 rounded-md hover:bg-white hover:text-[#2d3035] border border-white hover:border-[#2d3035] transition duration-300 ease-in-out shadow-md active:bg-gray-600 active:border-gray-600">
              Copy
            </button>
          </div>
          <span className="text-white">
            Payment System Powered By CryptoUpi.io
          </span>
        </div>
      </div>
    </div>
  );
}

export default WalletModal;
