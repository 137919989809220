import { createSelector } from "@reduxjs/toolkit";

const userSelector = (state) => state.user;

export const userData = createSelector([userSelector], (user) => user);

export const userProfile = createSelector(
  [userSelector],
  (user) => user.userData
);

export const userCoins = createSelector(
  [userSelector],
  (user) => user.userData?.userDetails?.balance
);

export const currencyIcons = createSelector(
  [userSelector],
  (user) => user.userData?.currencyIcon
);

export const userchats = createSelector([userSelector], (user) => user.chats);

export const betPlaced = createSelector(
  [userSelector],
  (user) => user.betPlaced
);

export const nBet = createSelector([userSelector], (user) => user.nBetData);

export const redBet = createSelector([userSelector], (user) => user.redBetData);

export const greenBet = createSelector(
  [userSelector],
  (user) => user.greenBetData
);

export const BlueBet = createSelector(
  [userSelector],
  (user) => user.blueBetData
);

export const betAmount = createSelector(
  [userSelector],
  (user) => user.betAmount
);

export const defaultUserCurrency = createSelector(
  [userSelector],
  (user) => user.defaultCurrency
);

export const nbetstatus = createSelector(
  [userSelector],
  (user) => user.nbetstate
);

export const nbetPlaced = createSelector(
  [userSelector],
  (user) => user.nBetData
);

export const rbetPlaced = createSelector(
  [userSelector],
  (user) => user.redBetData
);

export const gbetPlaced = createSelector(
  [userSelector],
  (user) => user.greenBetData
);

export const bbetPlaced = createSelector(
  [userSelector],
  (user) => user.blueBetData
);

export const normalBetLederBoard = createSelector(
  [userSelector],
  (user) => user.nbetLeaderBorad
);

export const redBetLederBoard = createSelector(
  [userSelector],
  (user) => user.rBetLeaderBoard
);

export const greenBetLederBoard = createSelector(
  [userSelector],
  (user) => user.gBetLeaderBoard
);

export const greenBetLoose = createSelector(
  [userSelector],
  (user) => user.greenBetLoose
);

export const redBetLoose = createSelector(
  [userSelector],
  (user) => user.redBetLoose
);

export const redBetActiveStatus = createSelector(
  [userSelector],
  (user) => user.redBetActive
);

export const greenBetActiveStatus = createSelector(
  [userSelector],
  (user) => user.greenBetActive
);

export const blueBetActiveStatus = createSelector(
  [userSelector],
  (user) => user.blueBetActive
);

export const userBetHistory = createSelector(
  [userSelector],
  (user) => user.userBetHistory
);