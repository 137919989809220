import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios/axiosInstance";
import axios from "axios";
import { cancelNBet, resetBetState, resetColorLeaderBorad } from "./UserSlice";

export const SignUpMetaMask = createAsyncThunk(
  "user/SignUpMetamask",
  async (obj) => {
    try {
      const metamaskSingUpResponse = await axiosInstance.post(
        "/user/signUp-metamask",
        obj
      );
      console.log("api data", metamaskSingUpResponse);
      return metamaskSingUpResponse;
    } catch (err) {
      if (err) {
        throw err;
      }
    }
  }
);

export const logInMetaMask = createAsyncThunk(
  "user/logInMetamsk",
  async (obj) => {
    try {
      const metamaskSingUpResponse = await axiosInstance.post(
        "/user/logIn-metamask",
        obj
      );
      console.log("api data", metamaskSingUpResponse);
      return metamaskSingUpResponse;
    } catch (err) {
      if (err) {
        throw err;
      }
    }
  }
);

export const googleAuth = createAsyncThunk("user/GoogleAuth", async (obj) => {
  try {
    const response = await axiosInstance.get("/auth/google");
    console.log("api data", response);
    return response;
  } catch (err) {
    if (err) {
      throw err;
    }
  }
});

export const nauthLogin = createAsyncThunk("user/nAuthLogin", async (obj) => {
  try {
    const metamaskSingUpResponse = await axiosInstance.post(
      "/user/logIn-Nauth",
      obj
    );
    console.log("api data", metamaskSingUpResponse);
    return metamaskSingUpResponse?.data;
  } catch (err) {
    if (err) {
      throw err;
    }
  }
});

export const nAuthSigup = createAsyncThunk("user/nAuthSignp", async (obj) => {
  try {
    const metamaskSingUpResponse = await axiosInstance.post(
      "/user/signUp-Nauth",
      obj
    );
    console.log("api data", metamaskSingUpResponse);
    return metamaskSingUpResponse?.data;
  } catch (err) {
    if (err) {
      throw err;
    }
  }
});

export const LoadUserData = createAsyncThunk(
  "user/loadUserData",
  async (obj) => {
    try {
      const metamaskSingUpResponse = await axiosInstance.post(
        "/user/signUp-metamask",
        obj
      );
      console.log("api data", metamaskSingUpResponse);
      return metamaskSingUpResponse;
    } catch (err) {
      if (err) {
        throw err;
      }
    }
  }
);

export const LoadInitialMsg = createAsyncThunk("user/getChats", async () => {
  try {
    const apiResponse = await axiosInstance.get("/user/get-chats");

    return apiResponse.data;
  } catch (err) {
    if (err) {
      throw err;
    }
  }
});

export const sendMsg = createAsyncThunk("user/sendMsg", async (obj) => {
  try {
    const apiResponse = await axiosInstance.post("/user/send-msg", obj);

    return apiResponse.data;
  } catch (err) {
    if (err) {
      throw err;
    }
  }
});

export const changeName = createAsyncThunk("user/sendMsg", async (obj) => {
  try {
    const apiResponse = await axiosInstance.post("/user/change-name", obj);

    return apiResponse.data.newName;
  } catch (err) {
    if (err) {
      throw err;
    }
  }
});

export const LoadLederBoard = createAsyncThunk(
  "user/loadUserData",
  async (obj) => {
    try {
      const metamaskSingUpResponse = await axiosInstance.post(
        "/user/signUp-metamask",
        obj
      );
      console.log("api data", metamaskSingUpResponse);
      return metamaskSingUpResponse;
    } catch (err) {
      if (err) {
        throw err;
      }
    }
  }
);

export const uploadProfileImage = createAsyncThunk(
  "user/uploadProfileImage",
  async ({ file, userId }, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("userId", userId);

      const response = await axios.post(
        "https://imgapidexcrash.web5lab.net/upload",
        formData
      );

      if (response.status !== 200) {
        throw new Error("Failed to upload the image");
      }

      return response.data.imagePath;
    } catch (err) {
      throw err;
    }
  }
);

export const placeNormalBet = createAsyncThunk("user/nBet", async (obj) => {
  try {
    const apiResponse = await axiosInstance.post("/user/place-normal-bet", obj);
    console.log("api response", apiResponse.data);
    return apiResponse.data;
  } catch (err) {
    if (err) {
      throw err;
    }
  }
});

export const placeGreenBetApi = createAsyncThunk("user/gBet", async (obj) => {
  try {
    const apiResponse = await axiosInstance.post("/user/place-green-bet", obj);
    console.log("api response", apiResponse.data);
    return apiResponse.data;
  } catch (err) {
    if (err) {
      throw err;
    }
  }
});

export const placeRedBetApi = createAsyncThunk("user/rBet", async (obj) => {
  try {
    const apiResponse = await axiosInstance.post("/user/place-red-bet", obj);
    console.log("api response", apiResponse.data);
    return apiResponse.data;
  } catch (err) {
    if (err) {
      throw err;
    }
  }
});

export const placeBlueBetApi = createAsyncThunk("user/bBet", async (obj) => {
  try {
    const apiResponse = await axiosInstance.post("/user/place-blue-bet", obj);
    console.log("api response", apiResponse.data);
    return apiResponse.data;
  } catch (err) {
    if (err) {
      throw err;
    }
  }
});

export const changeDefaultCurrency = createAsyncThunk(
  "user/changeDefaultCurrency",
  async (obj, { rejectWithValue }) => {
    try {
      const apiResponse = await axiosInstance.post(
        "/user/change-default-currency",
        obj
      );

      return apiResponse.data.currencyId;
    } catch (err) {
      if (err) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUserBetHistory = createAsyncThunk(
  "user/UserBetHistory",
  async (obj) => {
    try {
      const Response = await axiosInstance.get(
        `/user/get-bet-history?userId=${obj.userId}&page=${obj.page}`,
      );
      console.log("api data userBetHistory", Response);
      return Response.data.data;
    } catch (err) {
      if (err) {
        throw err;
      }
    }
  }
);

export const betHandler = createAsyncThunk(
  "user/betHandler",
  async (args, { getState, dispatch }) => {
    const state = getState();
    const loggedIn = state.user?.loggedIn;
    const betted = state.user?.betPlaced;
    const nBetData = state.user?.nBetData;
    const nbetState = state.user?.nbetstate;
    const rBetData = state.user?.redBetData;
    const gBetData = state.user?.greenBetData;
    const bBetData = state.user?.blueBetData;
    const gameState = state.game?.gameState;

    // console.log("game state: " + gameState);
    if (gameState === 0 ) {
      dispatch(resetColorLeaderBorad());
    }
    if (!loggedIn) {
      return;
    }

    if (!betted && !nbetState) {
      return;
    }



    if (gameState === 0 && nbetState) {
      dispatch(resetBetState());

    }

    if (rBetData && gameState === 0) {
      dispatch(placeRedBetApi(rBetData));
    }
    if (gBetData && gameState === 0) {
      dispatch(placeGreenBetApi(gBetData));
    }
    if (bBetData && gameState === 0) {
      dispatch(placeBlueBetApi(bBetData));
    }

    if (nBetData && gameState === 0) {
      try {
        dispatch(cancelNBet());
        dispatch(placeNormalBet(nBetData));
      } catch (error) {}
    }
  }
);

export const cashOutBeta = createAsyncThunk(
  "user/cashOut",
  async (obj, { rejectWithValue }) => {
    try {
      const apiResponse = await axiosInstance.post("/user/cash-out-beta", obj);

      return apiResponse.data;
    } catch (err) {
      if (err) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
