import React, { useState } from "react";
import {
  FaRegCheckCircle,
  FaArrowUp,
  FaArrowLeft,
  FaArrowDown,
} from "react-icons/fa";
import logoImg from "../../media/logo.png";
import icons from "../../dummydata/img.json";
import "./classicCard.css";

function ClassicPredictionCard(data) {
  const [isFlipped, setIsFlipped] = useState(false);
  const [IsUp, setIsUp] = useState(true);

  const handleCardClick = () => {
    setIsFlipped(!isFlipped);
  };

  const changeMode = () => {
    setIsUp(!IsUp);
  };



  return (
    <div
      className={`text-white w-[300px]  h-[400px]  flip-container ${
        isFlipped ? "flipped" : ""
      }`}
    >
      <div className="flipper ">
        <div
          className={`front  ${
            data.data.Active ? "border-[#ebd725] border-4  rounded-md" : ""
          }`}
          onClick={handleCardClick}
          style={{
            background:
              "linear-gradient(225deg, rgb(24 24 38) 0%, rgb(59 69 118) 100%)",
          }}
        >
          <div className="bg-black w-full h-12 rounded-md flex items-center pl-4 gap-4">
            <FaRegCheckCircle style={{ fontSize: "22px" }} />
            <h1 className="text-lg">Round #{data.data.RoundId}</h1>
          </div>
          <div className="flex flex-col justify-center items-center  w-full pt-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 240 65"
              fill="#3bc117"
              style={{ width: "250px" }}
            >
              <path d="M10.0001 49.2757L10.0003 64H234L234 49.2753C234 42.5136 229.749 36.4819 223.381 34.2077L138.48 3.8859C127.823 0.0796983 116.177 0.0796931 105.519 3.8859L20.6188 34.2076C14.2508 36.4819 10.0001 42.5138 10.0001 49.2757Z"></path>

              <foreignObject x="50" y="15" width="150" height="60">
                <div className="flex-col flex justify-center items-center">
                  <p className=" font-extrabold text-base">Up</p>
                  <p className=" font-extrabold text-base">2.0 X Payout</p>
                </div>
              </foreignObject>
            </svg>

            <div className="w-[260px] h-[168px] rounded-lg border-2  bg-[#181818] border-[#3bc117] flex flex-col justify-center items-center">
              {data.data.BetStopped ? (
                <div className="flex  justify-center items-center gap-4  w-full">
                  <div className="flex flex-col  items-end  font-bold text-xl">
                    <p>$45,834.000</p>
                    <p>$45,877.000</p>
                  </div>
                  <div
                    className={`flex h-full bg-[#3bc117] rounded-lg justify-center items-center px-3`}
                  >
                    {/* {data.data.} */}
                    <FaArrowUp style={{ fontSize: "22px", color: "white" }} />
                  </div>
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center gap-2 w-full">
                  <button className="w-[80%] py-2 rounded-lg bg-[#3bc117]">
                    Enter Up
                  </button>
                  <button className="w-[80%] py-2 rounded-lg bg-[#ed6300]">
                    Enter Down
                  </button>
                </div>
              )}

              <p className="w-full text-center text-base font-bold mt-2">
                Prize Pool
              </p>
              <div className="flex w-full h-auto justify-center items-center">
                <h1 className="bg-gradient-to-r   from-white to-blue-500 text-transparent bg-clip-text font-extrabold text-2xl">
                  10000 DEX
                </h1>
                <img src={logoImg} alt="logo" className="w-10 h-10"></img>
              </div>
            </div>

            <svg viewBox="0 0 240 65" fill="#ed6300" style={{ width: "250px" }}>
              <path d="M10.0001 15.7243L10.0003 1H234L234 15.7247C234 22.4864 229.749 28.5181 223.381 30.7923L138.48 61.1141C127.823 64.9203 116.177 64.9203 105.519 61.1141L20.6188 30.7924C14.2508 28.5181 10.0001 22.4862 10.0001 15.7243Z"></path>

              <foreignObject x="50" y="5" width="150" height="60">
                <div className="flex-col flex justify-center items-center">
                  <p className=" font-extrabold text-base">2.0 X Payout</p>
                  <p className=" font-extrabold text-base">Down</p>
                </div>
              </foreignObject>
            </svg>
          </div>
        </div>
        <div
          className="back  w-full"
          style={{
            background:
              "linear-gradient(225deg, rgb(24 24 38) 0%, rgb(59 69 118) 100%)",
          }}
        >
          <div className="bg-black w-full h-12 rounded-md flex items-center pl-4 gap-4">
            <FaArrowLeft
              style={{ fontSize: "22px" }}
              onClick={handleCardClick}
            />

            <h1 className="text-lg">Enter Round</h1>
            <div style={{ flex: 1 }}></div>
            <button
              onClick={changeMode}
              className={`flex mr-2 gap-2  ${
                IsUp ? "bg-[#3bc117]" : "bg-[#ed6300]"
              } justify-center items-center rounded-md py-2 w-[80px]`}
            >
              {IsUp ? (
                <FaArrowUp style={{ fontSize: "22px" }} />
              ) : (
                <FaArrowDown style={{ fontSize: "22px" }} />
              )}
              {IsUp ? "Up" : "Down"}
            </button>
          </div>
          <div className="mt-12 gap-2 px-2 flex flex-col justify-center items-center">
            <div className="w-full  mb-2 px-4 flex items-center justify-center h-[22px] text-white   ">
              <span className="mr-4 flex w-full">Bet Amount : </span>
              <div className={{ flex: 1 }}></div>
              <img
                className="w-[28px] h-[28px]"
                alt="logo"
                src={icons[0].icon}
              ></img>
              <span className=" text-lg font-bold ml-4">Dex</span>
            </div>
            <div className="w-full md:h-[44px] rounded-md h-10 flex justify-center items-center bg-[#31343c] opacity-4">
              <div className="opacity-100">
                <img
                  className="w-[28px] h-[28px]"
                  alt="logo"
                  src={icons[0].icon}
                ></img>
              </div>
              <input
                className="h-full md:w-[100px] ml-4 w-[34%] bg-transparent focus:border-none focus:outline-none p-2 text-white font-bold"
                placeholder="1.00"
                value={20}
              ></input>
              <div className="w-[136px]  flex justify-center items-center bg-[#31343c]  space-x-1 h-full py-1">
                <button className="w-[44px] cursor-pointer flex justify-center rounded  items-center h-[32px] md:h-[36px] bg-[#686b6e]  text-white">
                  /2
                </button>
                <button className="w-[44px] cursor-pointer flex justify-center rounded items-center h-[32px] md:h-[36px] bg-[#686b6e]  text-white">
                  X2
                </button>
                <button className="w-[44px] cursor-pointer flex justify-center rounded items-center md:h-[36px] h-[32px] bg-[#686b6e]  text-white">
                  Max
                </button>
              </div>
            </div>
            <div className="w-full   h-[40px] md:flex justify-center items-center  space-x-2 ">
              <button className="w-[81px] cursor-pointer h-[40px] text-white flex justify-center items-center bg-[#343435] rounded-md ">
                10
              </button>
              <button className="w-[81px] cursor-pointer h-[40px] text-white flex justify-center items-center bg-[#343435] rounded-md ">
                100
              </button>
              <button className="w-[81px] cursor-pointer h-[40px] text-white flex justify-center items-center bg-[#343435] rounded-md ">
                1000
              </button>
              <button className="w-[81px] cursor-pointer h-[40px] text-white flex justify-center items-center bg-[#343435] rounded-md ">
                10000
              </button>
            </div>
            <button
              className={`w-full mt-6 text-center text-lg font-bold ${
                IsUp ? "bg-[#3bc117]" : "bg-[#ed6300]"
              } h-12 rounded-xl`}
            >
              Place Bet
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClassicPredictionCard;
