import styled from 'styled-components';

export const div = styled.div``;

export const iconBox = styled.div`
   text-align: center;
   cursor: pointer;
   padding: 0.7rem;

   .icon {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
         width: 15px;
         height: 15px;
      }
   }

   @media (max-width: 500px) {
      .icon {
         img {
            width: 14px;
            height: 14px;
         }
      }

      p {
         font-size: 12px;
      }
   }
`;
