import React, { useState } from "react";
import { BsBarChartFill } from "react-icons/bs";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { MdSwapHorizontalCircle } from "react-icons/md";
import { FaRocket, FaParachuteBox } from "react-icons/fa";
import { BiSolidDashboard, BiLogoTelegram } from "react-icons/bi";
import { GrTwitter } from "react-icons/gr";
import { AiFillSetting, AiFillHome } from "react-icons/ai";
import { FaChartBar } from "react-icons/fa";
import { DollarOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";

const items = [
  {
    label: <Link to="/">Home</Link>,
    key: "home",
    route:"/",
    icon: (
      <AiFillHome
        style={{ color: "white", fontSize: "24px", display: "inline" }}
      />
    ),
  },
  {
    label: <Link to="/betaprediction">Prediction</Link>,
    key: "Leaderboard",
    route:"betaprediction",
    icon: (
      <FaChartBar
        style={{ color: "white", fontSize: "24px", display: "inline" }}
      />
    ),
  },
  {
    label: <Link to="/ReferAndEarn">DashBoard</Link>,
    key: "ReferAndEarn",
    route:"/",
    icon: (
      <BiSolidDashboard
        style={{ color: "white", fontSize: "24px", display: "inline" }}
      />
    ),
  },
  {
    label: "Buy Dex Token",
    key: "launchpads",
    route:"/",
    icon: (
      <FaRocket
        style={{ color: "white", fontSize: "24px", display: "inline" }}
      />
    ),
  },
  {
    label: "Join Airdrop",
    key: "Private Sale",
    route:"/",
    icon: (
      <FaParachuteBox
        style={{ color: "white", fontSize: "24px", display: "inline" }}
      />
    ),
  },
  {
    label: "Swap",
    key: "PinkLock",
    route:"/",
    icon: (
      <MdSwapHorizontalCircle
        style={{ color: "white", fontSize: "24px", display: "inline" }}
      />
    ),
  },
  {
    label: "Refer And Earn",
    key: "Airdrop",
    route:"/",
    icon: (
      <FaMoneyBillTrendUp
        style={{ color: "white", fontSize: "24px", display: "inline" }}
      />
    ),
  },
  {
    label: <Link to="Buy Crypto">Buy Crypto Fiat</Link>,
    key: "BuycryptoFiat",
    route:"/",
    icon: <DollarOutlined style={{ color: "white", fontSize: "24px" }} />,
  },
];

const SimpleButton = ({ item, onClick, current, collapsed }) => {
  const navigation = useNavigate();
  return (
    <button
      onClick={() => (navigation(`${item.route}`))}
      className={` mb-1 mt-1  text-left flex items-center mx-4 hover:bg-gray-500 ${
        collapsed ? " h-[52px] justify-center rounded-md  w-[52px]" : "w-full  h-[52px]"
      } ${current === item.key ? "primary-button" : ""}`}
    >
      {collapsed ? (
        <span style={{ fontWeight: "bold" }}>{item.icon}</span>
      ) : (
        <>
          {item.icon}{" "}
          <Link
            to={item.key === "home" ? "/" : item.key}
            className={current === item.key ? "hidden" : ""}
            style={{ color: "white", marginLeft:"1rem" }}
          >
            {item.label}
          </Link>
        </>
      )}
    </button>
  );
};

const SideBar = ({ collapsed }) => {
  const [current, setCurrent] = useState("1");

  const onClick = (item) => {
    setCurrent(item.key);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        {items.map((item, index) => (
          <SimpleButton
            key={index}
            item={item}
            onClick={onClick}
            current={current}
            collapsed={collapsed}
          />
        ))}
        <div style={{ flex: 1 }}></div>
      </div>
      <div className="mt-4 w-full flex flex-col justify-center items-center h-[114px] px-4 text-white">
        {collapsed ? (
          <div className="w-[52px] h-[52px] hover:bg-gray-500 flex justify-center items-center">
            <AiFillSetting style={{ color: "white", fontSize: "24px" }} />
          </div>
        ) : (
          <>
            <div className="flex justify-center items-center space-x-4 mb-2">
              <div className="p-2 bg-gray-800 rounded-md">0.078 $</div>
              <div className="p-2 border-gray-800 border-[1px] rounded-md">
                <BiLogoTelegram style={{ color: "white", fontSize: "24px" }} />
              </div>
              <div className="p-2 border-gray-800 border-[1px] rounded-md">
                <GrTwitter style={{ color: "white", fontSize: "24px" }} />
              </div>
            </div>
            <div className="p-2 bg-gray-800 rounded-md">
              <span>Build With ❤️ By WEB5LAB</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SideBar;
