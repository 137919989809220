import React, { useRef, useState } from "react";
import {
  FaGamepad,
  FaDollarSign,
  FaCheck,
  FaTimes,
  FaPencilAlt,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { userProfile } from "../../selector/userSelector";
import { changeName, uploadProfileImage } from "../../App/features/UserAction";
import icons from '../../dummydata/img.json'

function ProfileModal() {
  const [imageAlert, setImageAlert] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedUsername, setEditedUsername] = useState();

  const imageInputRef = useRef(null);

  const handleImageEdit = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
  };

  const profile = useSelector(userProfile);
  const dispatch = useDispatch();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const userId = profile?.userDetails?._id;
      console.log(userId);

      dispatch(uploadProfileImage({ file, userId }))
        .then((action) => {
          if (uploadProfileImage.fulfilled.match(action)) {
            setImageAlert(true);
          }
        })
        .catch((error) => {
          console.error("There was an error uploading the image", error);
        });
    }
  };

  // Handlers for editing username
  const startEditing = () => {
    setEditedUsername(profile?.userDetails?.name); // Initialize with current username
    setIsEditing(true);
  };

  const saveEditing = () => {
    const obj = {
      newName: editedUsername,
      userId: profile?.userDetails?._id,
    };
    dispatch(changeName(obj));
    setIsEditing(false);
  };

  const totalWageredAmount = "$1500";

  const coinBalances = {
    DEX: { balance: Number(profile?.userDetails?.balance[0]?.balance.$numberDecimal), icon: icons[0].icon },
    DEXT: { balance: Number(profile?.userDetails?.balance[1]?.balance.$numberDecimal), icon: icons[1].icon },
    BETA: { balance: Number(profile?.userDetails?.balance[2]?.balance.$numberDecimal), icon: icons[2].icon },
  };

  const stats = [
    { label: "Total Wins", value: 150, icon: <FaGamepad /> },
    { label: "Total Bets", value: 150, icon: <FaGamepad /> },
    { label: "Referral Earnings", value: "$200", icon: <FaDollarSign /> },
    { label: "Total Wagered", value: totalWageredAmount },
  ];

  return (
    <div className="bg-gradient-to-br from-blue-600 to-gray-700 p-8 h-auto flex flex-col justify-center items-center shadow-xl rounded-lg space-y-8">
      <div className="flex flex-col items-center">
        <div className="flex flex-col items-center relative">
          <img
            src={profile?.userDetails?.profilePic}
            alt="Profile"
            className="w-40 h-40 rounded-full shadow-md border-8 border-white"
          />
          <button
            onClick={handleImageEdit}
            className="absolute bottom-2 w-full text-lg  text-white font-bold  flex justify-center items-center p-2  "
          >
            Edit{" "}
          </button>
          <input
            type="file"
            ref={imageInputRef}
            onChange={handleImageChange}
            accept="image/*"
            style={{ display: "none" }}
          />
        </div>

        {isEditing ? (
          <>
            <div className="flex justify-center items-center">
              <input
                type="text"
                value={editedUsername}
                onChange={(e) => setEditedUsername(e.target.value)}
                className="text-white text-2xl font-semibold bg-transparent border-none focus:outline-none  w-auto"
                autoFocus
              />
              <button onClick={saveEditing} className="ml-2 text-green-500">
                <FaCheck style={{ fontSize: "18px" }} />
              </button>
              <button
                onClick={() => setIsEditing(false)}
                className="ml-2 text-red-500"
              >
                <FaTimes style={{ fontSize: "18px" }} />
              </button>
            </div>
          </>
        ) : (
          <div className="flex items-center">
            <span className="text-white text-2xl font-semibold mr-4">
              {profile?.userDetails?.name}
            </span>
            <button onClick={startEditing} className="text-white">
              <FaPencilAlt />
            </button>
          </div>
        )}

        <div className="text-white text-sm font-semibold">
          {profile?.userDetails?.userName}
        </div>
      </div>

      {imageAlert && (
        <div className="text-white bg-green-500 p-2 rounded text-center">
          Image Updated!
        </div>
      )}

      {/* User Statistics */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full max-w-xl">
        {stats.map((stat, idx) => (
          <div
            key={idx}
            className="flex items-center justify-between bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow hover:bg-opacity-90"
          >
            {stat.icon && (
              <span className="text-[#ff6b81] text-2xl">{stat.icon}</span>
            )}
            <div className="text-[#ff6b81] text-md font-semibold">
              {stat.label}
            </div>
            <div className="text-[#985cff] font-bold text-lg">{stat.value}</div>
          </div>
        ))}
      </div>

      {/* Coin Balances */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full max-w-xl">
        {Object.keys(coinBalances).map((coin) => (
          <div
            key={coin}
            className="flex items-center justify-between bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow hover:bg-opacity-90"
          >
            <img className="text-[#ff6b81] text-2xl w-8" alt="icon"  src= {coinBalances[coin].icon}>
             
            </img>
            <div className="text-[#ff6b81] text-md font-semibold">{coin}</div>
            <div className="text-[#985cff] font-bold text-lg">
              {coinBalances[coin].balance}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProfileModal;
