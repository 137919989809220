import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as PIXI from "pixi.js-legacy";
import { Application, Graphics, Ticker } from "pixi.js-legacy";

import { SocketContext } from "../../Context/SocketContext";
import { useDispatch } from "react-redux";
import { updateGameStae } from "../../App/features/GameSlice";
import { addNBetLeaderBoard } from "../../App/features/UserSlice";
import { betHandler } from "../../App/features/UserAction";

const cashOutSpeed = 0.5;
const gravity = 0.1;
const gradientSegments = 100;
const color1 = [255, 255, 255]; // White
const color2 = [0, 0, 255]; // Blue
const xAxis = new PIXI.Graphics();
const yAxis = new PIXI.Graphics();
const cashOutGraphics = [];
let gameState = 0;

let multiplier = 1;
let crashpoint = 1;

export let testmn;
const useCrashComp = () => {
  const [app, setApp] = useState(null);
  const appRef = useRef(null);
  const socket = useContext(SocketContext);
  const [gameValue, setgameValue] = useState(null);
  const [crashState, setcrashState] = useState(null);
  const [CrashValue, setCrashValue] = useState();
  const containerRef = useRef(new Graphics());
  const dispatch = useDispatch();

  const container = containerRef.current;
  const line = useMemo(() => new PIXI.Graphics(), []);

  line.zIndex = 1;

  useEffect(() => {
    const handldegameUpdate = ({ multipliers }) => {
      setCrashValue(multipliers.toFixed(2));
      multiplier = multipliers;
      console.log("CRASH value", CrashValue, multipliers.toFixed(2));
    };
    socket.on("multiplier", handldegameUpdate);

    return () => {
      socket.off("multiplier", handldegameUpdate);
    };
  });

  container.addChild(line);

  function drawGradientLine(startPoint, endPoint) {
    // Clear the Graphics object
    line.clear();

    // Define the control point for the curve
    const controlPoint = {
      x: (startPoint.x + endPoint.x) / 2,
      y: startPoint.y,
    };

    // Draw gradient curve using multiple line segments
    for (let i = 0; i <= gradientSegments; i++) {
      const t = i / gradientSegments;

      // Interpolate color
      const r = Math.round(color1[0] * (1 - t) + color2[0] * t);
      const g = Math.round(color1[1] * (1 - t) + color2[1] * t);
      const b = Math.round(color1[2] * (1 - t) + color2[2] * t);
      const color = (r << 16) + (g << 8) + b;

      // Interpolate position along the curve using quadratic bezier curve formula
      const x =
        (1 - t) * (1 - t) * startPoint.x +
        2 * (1 - t) * t * controlPoint.x +
        t * t * endPoint.x;
      const y =
        (1 - t) * (1 - t) * startPoint.y +
        2 * (1 - t) * t * controlPoint.y +
        t * t * endPoint.y;
      const nx =
        (1 - (t + 1 / gradientSegments)) *
          (1 - (t + 1 / gradientSegments)) *
          startPoint.x +
        2 *
          (1 - (t + 1 / gradientSegments)) *
          (t + 1 / gradientSegments) *
          controlPoint.x +
        (t + 1 / gradientSegments) * (t + 1 / gradientSegments) * endPoint.x;
      const ny =
        (1 - (t + 1 / gradientSegments)) *
          (1 - (t + 1 / gradientSegments)) *
          startPoint.y +
        2 *
          (1 - (t + 1 / gradientSegments)) *
          (t + 1 / gradientSegments) *
          controlPoint.y +
        (t + 1 / gradientSegments) * (t + 1 / gradientSegments) * endPoint.y;

      // Draw small line segment
      line.lineStyle(8, color, 1);
      line.moveTo(x, y);
      line.lineTo(nx, ny);
    }
  }

  container.addChild(xAxis);
  container.addChild(yAxis);

  useEffect(() => {
    const handldegameUpdate = ({ data, gameStatus }) => {
      crashpoint = data;
      gameState = gameStatus;
      dispatch(updateGameStae(gameStatus));
      dispatch(betHandler());
      if (gameStatus == 0) {
        setgameValue(crashpoint);
        setcrashState(0);
        multiplier = 1;
      }
      if (gameStatus == 1) {
        setgameValue(data);
        setcrashState(1);
        multiplier = 1;
      } else {
        testmn = `${multiplier}`;
      }
    };

    socket.on("update", handldegameUpdate);

    return () => {
      socket.off("update", handldegameUpdate);
    };
  }, []);

  useLayoutEffect(() => {
    const handldegameUpdate = ({ multipliers, serverSpeed, gameStatus }) => {
      multiplier = multipliers;

      gameState = gameStatus;
      dispatch(updateGameStae(gameStatus));
      if (gameStatus == 0) {
        testmn = `game is crashed at ${crashpoint}`;
      }
      if (gameStatus == 1) {
        testmn = `game is starting in 10 second`;
      } else {
        testmn = `${multiplier}`;
      }
    };

    socket.on("initialData", handldegameUpdate);

    return () => {
      socket.off("initialData", handldegameUpdate);
    };
  }, []);

  useEffect(() => {
    const initPixiApp = () => {
      const pixiApp = new Application({
        width: appRef.current.offsetWidth,
        height: appRef.current.offsetHeight,
        backgroundColor: "black",
      });

      socket.on("testSocket", ({ testDb, userBet }) => {
        const cashOutGraphic = new PIXI.Text(testDb, {
          fontSize: 16,
          fill: "white",
        });

        dispatch(addNBetLeaderBoard(userBet.userId));

        cashOutGraphic.position.set(endPoint.x, endPoint.y);
        cashOutGraphic.pivot.set(
          cashOutGraphic.width / 2,
          cashOutGraphic.height / 2
        );
        cashOutGraphic.speed = cashOutSpeed;
        cashOutGraphic.gravity = gravity;
        cashOutGraphics.push(cashOutGraphic);

        container.addChild(cashOutGraphic);
      });

      appRef.current.appendChild(pixiApp.view);
      // Create a container to hold everything
      const startPoint = { x: 50, y: pixiApp.screen.height - 50 };
      pixiApp.stage.addChild(container);

      let endPoint = { x: startPoint.x, y: startPoint.y }; // Initialize endPoint at the same position as startPoint

      // Draw axes
      xAxis.lineStyle(2, "white", 1);
      yAxis.lineStyle(2, "white", 1);
      xAxis.moveTo(50, pixiApp.screen.height - 50);
      xAxis.lineTo(pixiApp.screen.width - 50, pixiApp.screen.height - 50);
      yAxis.moveTo(50, pixiApp.screen.height - 50);
      yAxis.lineTo(50, 50);

      // Draw scale on the axes
      xAxis.lineStyle(1, "white", 0.5);
      yAxis.lineStyle(1, "white", 0.5);

      // Add a ticker to the app
      Ticker.shared.add(() => {
        if (gameState === 0) {
          testmn = `game is crashed at ${crashpoint}`;
        }
        if (gameState === 1) {
          testmn = `game is starting in 10 second`;
        } else {
          testmn = `${multiplier.toFixed(2)}`;
          setgameValue(multiplier.toFixed(2));
        }

        endPoint.x =
          startPoint.x +
          (pixiApp.screen.width - startPoint.x) * (multiplier - 1);
        endPoint.y = startPoint.y - (startPoint.y - 50) * (multiplier - 1);

        // Ensure the endPoint stays within the screen boundaries
        endPoint.x = Math.min(endPoint.x, pixiApp.screen.width - 50);
        endPoint.y = Math.max(endPoint.y, 50);

        // Draw the gradient line
        drawGradientLine(startPoint, endPoint);

        cashOutGraphics.forEach((cashOutGraphic, index) => {
          cashOutGraphic.position.y += cashOutGraphic.speed;
          cashOutGraphic.position.x -= cashOutGraphic.speed;
          cashOutGraphic.speed += cashOutGraphic.gravity;
          cashOutGraphic.alpha -= 0.01;
          if (cashOutGraphic.alpha <= 0) {
            container.removeChild(cashOutGraphic);
            cashOutGraphics.splice(index, 1);
          }
        });
      });
      setApp(pixiApp); // Set the app once initialization is done.
    };

    if (!app) {
      initPixiApp();
    }
  }, [app]);

  return { appRef, gameValue, crashState };
};

export default useCrashComp;
