import React, { useContext, useEffect, useLayoutEffect, useRef } from "react";
import icons from "../../dummydata/img.json";
import {
  MdKeyboardDoubleArrowUp,
  MdKeyboardDoubleArrowDown,
} from "react-icons/md";

import { FaPlus, FaMinus } from "react-icons/fa";

import { createChart, CrosshairMode } from "lightweight-charts";
import { useDispatch, useSelector } from "react-redux";
import { btcChartDataApi } from "../../App/features/GameAction";
import { btcChartSelector } from "./selector";
import { SocketContext } from "../../Context/SocketContext";

function AdvancedPrediction() {
  const chartContainerRef = useRef(null);
  const dispatch = useDispatch();
  const btcChartData = useSelector(btcChartSelector);
  const socket = useContext(SocketContext);

  useLayoutEffect(() => {
    dispatch(btcChartDataApi());
  }, [dispatch]);

  useEffect(() => {
    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.offsetWidth,
      height: chartContainerRef.current.offsetHeight,
      rightPriceScale: {
        scaleMargins: {
          top: 0.3,
          bottom: 0.25,
        },
        mode: CrosshairMode.Normal,
      },
      layout: {
        backgroundColor: "black",
        textColor: "white",
        fontSize: 12,
      },
      grid: {
        vertLines: {
          color: "rgba(42, 46, 57, 0)",
        },
        horzLines: {
          color: "rgba(42, 46, 57, 0)",
        },
      },
      timeScale: {
        timeVisible: true,
        secondsVisible: true,
      },
      watermark: {
        text: "Btc",
        color: "white",
        visible: true,
      },
    });

    const areaSeries = chart.addAreaSeries({
      topColor: "rgba(13, 48, 255, 0.56)",
      bottomColor: "rgba(13, 48, 255, 0.08)",
      lineColor: "rgba(13, 48, 255, 1)",
      lineWidth: 4,
      lastPriceAnimation: 1,
    });

    areaSeries.setData(btcChartData);
    console.log(btcChartData);

    const handleSocketChartData = (data) => {
      console.log("socket data", data);
      areaSeries.update(data);
    };
    socket.on("BtcChartData", handleSocketChartData);

    return () => {
      socket.off("BtcChartData", handleSocketChartData);
      chart.remove();
    };
  }, [btcChartData, socket]);
  return (
    <div className="flex lg:flex-row sm:flex-col flex-col justify-center gap-2 items-center">
      <div className="w-[100%] h-[70vh]">
        <div className="w-full h-full" ref={chartContainerRef}></div>
      </div>
      <div className=" w-[40%] h-[70Vh]">
        <div className="text-[18px] font-extrabold text-[#98a7b5] mr-4">
          <div className=" w-full bg-[#1e2024] flex flex-col gap-2 p-4 h-[70vh]">
            <div>
              <div className="Info_container__wwIvv text-white">
                <div class="Info_bl__zaSor"></div>
                <div class="Info_pr__3p1lI">
                  <div class="Info_lbl__4GCFj">85%</div>
                  <svg class="" viewBox="0 0 100 100">
                    <circle
                      stroke-width="6"
                      cx="50"
                      cy="50"
                      r="44"
                      class="Info_cr__aaXjQ"
                    ></circle>
                    <path
                      stroke-width="6"
                      d="M 50 6
          A 44 44 0 1 1 14.403252247502309 24.137448899131186"
                      class="Info_arc__PoMRn"
                    ></path>
                  </svg>
                </div>
                <div class="Info_c1__7FKr-">
                  <div>Payout</div>
                  <div className="Info_l1__T6mwk text-green-500 ">37 DEX</div>
                </div>
                <div class="Info_sl__dkJ59"></div>
                <div class="Info_c2__ICbcI">
                  <div>Profit</div>
                  <div class="Info_l2__7a7kD">17 DEX</div>
                </div>
              </div>
            </div>

            <h1 className="text-[16px]">CHOOSE PREDICTION TIME</h1>
            <div className="flex justify-center items-center">
              <button className=" text-white flex rounded-md justify-center items-center w-12  py-2 text-[18px]">
                {" "}
                <FaPlus style={{ fontSize: "28px", color: "white" }} />
              </button>
              <span className="w-full text-center">1 Min</span>
              <button className="w-12 text-white flex rounded-md justify-center items-center  py-2 text-[18px]">
                <FaMinus style={{ fontSize: "28px", color: "white" }} />
              </button>
            </div>
            <div className="w-full hidden  h-[40px] md:flex justify-center items-center mt-2 space-x-2 ">
              <button className="w-[81px] cursor-pointer h-[40px] text-white flex justify-center items-center bg-[#343435] rounded-md ">
                5 MIN
              </button>
              <button className="w-[81px] cursor-pointer h-[40px] text-white flex justify-center items-center bg-[#343435] rounded-md ">
                10 MIN
              </button>
              <button className="w-[81px] cursor-pointer h-[40px] text-white flex justify-center items-center bg-[#343435] rounded-md ">
                15 MIN
              </button>
              <button className="w-[81px] cursor-pointer h-[40px] text-white flex justify-center items-center bg-[#343435] rounded-md">
                20 MIN
              </button>
            </div>

            <div className="w-full mb-2 flex items-center h-[22px] text-white   ">
              {" "}
              <span className="mr-4">Amount</span>
              <div className="flex justify-center items-center w-[16px] text-[14px] rounded-md h-[16px] border-blue-600 font-bold text-blue-600 border-[2px]">
                !
              </div>
            </div>
            <div className="w-full md:h-[44px] rounded-md h-10 flex justify-center items-center bg-[#31343c] opacity-4">
              <div className="opacity-100">
                <img
                  className="w-[28px]  h-[28px]"
                  alt="logo"
                  src={icons[0].icon}
                ></img>
              </div>
              <input
                className="h-full md:w-[164px] ml-4 w-[34%] bg-transparent focus:border-none focus:outline-none p-2 text-white font-bold"
                placeholder="1.00"
                value={20}
              ></input>
              <div className="w-[136px]  flex justify-center items-center bg-[#31343c]  space-x-1 h-full py-1">
                <button className="w-[44px] cursor-pointer flex justify-center rounded  items-center h-[32px] md:h-[36px] bg-[#686b6e]  text-white">
                  /2
                </button>
                <button className="w-[44px] cursor-pointer flex justify-center rounded items-center h-[32px] md:h-[36px] bg-[#686b6e]  text-white">
                  X2
                </button>
                <button className="w-[44px] cursor-pointer flex justify-center rounded items-center md:h-[36px] h-[32px] bg-[#686b6e]  text-white">
                  Max
                </button>
              </div>
            </div>
            <div className="w-full hidden  h-[40px] md:flex justify-center items-center mt-2 space-x-2 ">
              <button className="w-[81px] cursor-pointer h-[40px] text-white flex justify-center items-center bg-[#343435] rounded-md ">
                10
              </button>
              <button className="w-[81px] cursor-pointer h-[40px] text-white flex justify-center items-center bg-[#343435] rounded-md ">
                100
              </button>
              <button className="w-[81px] cursor-pointer h-[40px] text-white flex justify-center items-center bg-[#343435] rounded-md ">
                1000
              </button>
              <button className="w-[81px] cursor-pointer h-[40px] text-white flex justify-center items-center bg-[#343435] rounded-md ">
                10000
              </button>
            </div>
            <div className="flex justify-center items-center gap-2">
              <button className="bg-green-500 text-white flex rounded-md justify-center items-center w-full  py-2 text-[18px]">
                {" "}
                <MdKeyboardDoubleArrowUp
                  style={{ fontSize: "28px", color: "white" }}
                />
                Up
              </button>
              <button className="bg-orange-500 text-white flex rounded-md justify-center items-center w-full py-2 text-[18px]">
                <MdKeyboardDoubleArrowDown
                  style={{ fontSize: "28px", color: "white" }}
                />
                Down
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvancedPrediction;
