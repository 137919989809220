import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/UserSlice"
import gameReducer from "./features/GameSlice"
import globalReducer from "./features/Global"


const store = configureStore({
  reducer: {
    user: userReducer,
    game:gameReducer,
    global:globalReducer,
  },
});

export default store;
