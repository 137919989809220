import React, { useEffect, useState } from "react";
import "./Navbar.css";
import "./TopNav.css";
import logoImg from "../../media/logo.png";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MessageTwoTone,
} from "@ant-design/icons";
import { HiOutlineLogout } from "react-icons/hi";
import { Button, Modal, theme } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { userData, userProfile } from "../../selector/userSelector";
import {
  LogInModal,
  closeProfileModal,
  closeWalletModal,
  logOut,
  openProfileModal,
} from "../../App/features/UserSlice";
import Login from "../auth/Login";
import SignUp from "../auth/SignUp";
import WalletModal from "../profile/WalletModal";
import { openSignUpModal } from "../../App/features/UserSlice";
import { closeSignUpModal } from "../../App/features/UserSlice";
import ProfileModal from "../profile/profile";
import WalletBtn from "./WalletBtn";

const TopNav = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const [chatComp, setchatComp] = useState(false);
  const user = useSelector(userData);
  const userProfileData = useSelector(userProfile);

  const dispatch = useDispatch();

  const closeWallet = () => {
    dispatch(closeWalletModal());
  };

  const closeProfile = () => {
    dispatch(closeProfileModal());
  };
  

  const closeSignUp = () => {
    dispatch(closeSignUpModal());
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    props.CollapsedClose(collapsed);
    props.chatController(!chatComp);
  });

  const emitResizeEvent = () => {
    setchatComp(!chatComp)
  
    const event = new Event('resize');
    window.dispatchEvent(event);
  };

  return (
    <>
      <Modal
        width={550}
        open={user?.logInModal}
        footer={null}
        onCancel={() => {
          dispatch(LogInModal(false));
        }}
        bodyStyle={{
          backgroundColor: "red",
        }}
      >
        <Login />
      </Modal>
      <Modal
        width={550}
        open={user?.signUpModal}
        footer={null}
        onCancel={closeSignUp}
        bodyStyle={{
          backgroundColor: "red",
        }}
      >
        <SignUp />
      </Modal>

      <Modal
        width={520}
        open={user?.walletModal}
        footer={null}
        onCancel={closeWallet}
        bodyStyle={{
          backgroundColor: "red",
        }}
      >
        <WalletModal />
      </Modal>
      <Modal
        width={520}
        open={user?.profileModal}
        footer={null}
        onCancel={closeProfile}
      >
        <ProfileModal />
      </Modal>
      <div className="sliderr">
        <div className=" hidden md:block p-2">
          <Button
            type="text"
            icon={
              collapsed ? (
                <MenuUnfoldOutlined
                  style={{ color: "white", fontSize: "28px" }}
                />
              ) : (
                <MenuFoldOutlined
                  style={{ color: "white", fontSize: "28px" }}
                />
              )
            }
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
        </div>
        <div className="inner-sidebar">
          <div>
            <img src={logoImg} alt=" "  className="   md:w-[72px] w-[52px] max-w-[64px] "  />
            <span className="bg-gradient-to-r  hidden  from-white to-blue-500 text-transparent bg-clip-text font-extrabold text-base md:block  lg:text-[2rem] md:text-[2rem]">
              Dex Crash
            </span>
          </div>
          <div className=" flex items-end md:mr-[2rem] mr-1 md:space-x-[12px]">
            {user?.loggedIn ? (
              <>
                <WalletBtn />
              </>
            ) : (
              <>
                {" "}
                <button
                  className="  text-white font-extrabold text-[14px] w-20 h-10 md:w-[112px] md:h-[44px] flex justify-center items-center "
                  onClick={() => {
                    dispatch(LogInModal(true));
                  }}
                >
                  Log In
                </button>
                <button
                  className=" bg-blue-700 text-white rounded mr-2 md:mr-0 font-extrabold text-[14px]  w-20 h-10 md:w-[112px] md:h-[44px] flex justify-center items-center hover:bg-blue-900"
                  onClick={() => {
                    dispatch(openSignUpModal());
                  }}
                >
                  Sign Up
                </button>
              </>
            )}

            <button
              className="w-[44px] h-[44px] hidden lg:block hover:bg-gray-500 cursor-pointer"
              onClick={() => 
              emitResizeEvent()}
            >
              <MessageTwoTone style={{ fontSize: "28px" }} className="" />
            </button>
            {user.loggedIn ? (
              <div
                className="md:w-[50px] w-[40px] md:h-[48px] flex justify-center items-center bg-[#24262b]"
                onClick={() => {
                  dispatch(openProfileModal());
                }}
              >
                <img
                  src={userProfileData?.userDetails?.profilePic}
                  alt="profile"
                  className=" rounded-[50%] h-[36px] w-[36px] "
                ></img>
              </div>
            ) : (
              <></>
            )}

           
            {user.loggedIn ? (
              <>
              
                <div
                  className="md:w-[44px] w-[40px] md:h-[44px] hover:bg-gray-500 cursor-pointer"
                  onClick={() => {
                    dispatch(logOut());
                  }}
                >
                  <HiOutlineLogout
                    style={{  color: "white" }}
                    className="md:text-[28px text-[24px]"
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TopNav;
