import React from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import * as styled from "./MobileFooterNavbarComponent.style";
import { AnimatePresence, motion } from "framer-motion";
import { CgGames , CgProfile } from "react-icons/cg";
import {PiWalletDuotone} from 'react-icons/pi'
import {HiChatBubbleBottomCenterText} from 'react-icons/hi2'

const MenuAr = [
  {
    name: "crash",
    id: 1,
    icon: <CgGames className=" text-white text-lg" />,
  },
  {
    name: "chat",
    id: 2,
    icon: <HiChatBubbleBottomCenterText className="text-white text-lg" />,
  },
  {
    name: "Wallet",
    id: 3,
    icon: <PiWalletDuotone className=" text-white text-lg"/>,
  },
  {
    name: "Profile",
    id: 4,
    icon: <CgProfile className=" text-white text-lg"/>,
  },
];

function MobileBottomNavigation({ showMenu }) {
  const navigation = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });

  const boxHandler = function (id, name) {
    if (name === "crash") {
      navigation("/");
    }

    if (name === "chat") {
      navigation("/chat");
    }
  };

  return (
    <AnimatePresence>
      {isMobile && (
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 100 }}
          className="bg-black fixed bottom-0 w-full"
        >
          <div className="flex items-center justify-between">
            {MenuAr.map((el) => (
              <styled.iconBox
                key={el.id}
                onClick={() => boxHandler(el.id, el.name)}
              >
                <div className="icon">{el.icon}</div>
                <p className="mt-2 text-gray-400 font-medium text-sm">
                  {el.name}
                </p>
              </styled.iconBox>
            ))}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default MobileBottomNavigation;
